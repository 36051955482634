import React, {useState} from "react";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import {API_URL} from "../../env";

const variants = {
    open: {opacity: 1, x: 0},
    closed: {opacity: 0, x: "50%"},
};

function saveLoginInfo() {
    let user =
        '{"id":10,"f_name":"bushra","l_name":"mohammad","name":null,"email":"bushra@axtro-soft.com","phone":"9639777","image":"def.png","login_medium":null,"is_active":1,"social_id":null,"is_phone_verified":0,"temporary_token":null,"wallet_balance":null,"token":{"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNjhlMGIxN2JmYmNkZWU3MGZhZmVkMWZlYWZlN2YxZDRlZWRkNmZhYjRmZjdjNWFiZTAwNjkzNjZiMzlmYTA5YzdhNWYyNThiNjBiMGQ1NjAiLCJpYXQiOjE2ODQ0MDYyMDYuMDQ1MTQyLCJuYmYiOjE2ODQ0MDYyMDYuMDQ1MTQ0LCJleHAiOjE3MTYwMjg2MDYuMDM3Njk2LCJzdWIiOiIxMCIsInNjb3BlcyI6W119.RSUHrOOQN9MG2g1DDoR_qaXVUKat6reGj98t4wPjuMmaYmZVlNIi3vLYsNmQ6Ds-1bXPH5Wyzn12CftM3GL_lLJgImr7IWLQmi4vkgKtjMg_75UaLuVg2AYkhZP6xHKsIh1b8uXxx4uPIWhZb3s5VIhoLHx2cDlujDl4fiqnoziCjNAVX280tsFVF549b0iqPYlJ6CDE7d9d1bEfX-pl1tiImwNYTk5uq7hcPDcbQgqznt6fBk0UDnwm3oKLiLYciJi0_Y78y7dt5lJ0Pr81QaAoRsZwecVrwYJZenv7SPn4qc5VH9P4hhRXd1ZZ0Jq9L3zFmApdXj2VsdU2goAChoLNa4TLmeKJ6EeUnQooAZaCnzfCyiMpgAVVj1YMHcRWytzTO0APkXawpcOhLy3wOdAOF_U0_TMiC_Ma2cP7YK_8cyDg6J5ZhDo0B6gZsjCTtiB8w4LY1z4clSrAPpkN3wf2x8gjkaC4KXdY5HN8mbmbZkoKQEMVwKQnvmsmZQUnHttbsr1js2d_9EHPoy4mtqB2azCg9gK_6GXFjYp3PdQ95tiiKmQqFLq0hlWFh49pYFcEMoQYSw89mGn23gu0FXhYurdGkN6ntBTvVS_v1EhGUPl68q8mLYxt5NjjH4LasEE4bWrxyWfFyTBWurNDY-bn-bvL4ofkAyfnCBSykuQ","token_type":"Bearer","expires_at":"2023-05-25 10:36:46"}}';
    // localStorage.setItem("user", user);
}

function getAccessToken() {
    let user = localStorage.getItem("user");
    return user ? JSON.parse(user)["token"]["access_token"] : null;
}

function LogInButton() {
    const logged = () => {
        const userLogged = {
            "phpdebugbar-open": "0",
            "phpdebugbar-visible": "1",
            "phpdebugbar-tab": "messages",
            "phpdebugbar-height": "140",
            user: '{"id":10,"f_name":"bushra","l_name":"mohammad","name":null,"email":"bushra@axtro-soft.com","phone":"9639777","image":"def.png","login_medium":null,"is_active":1,"social_id":null,"is_phone_verified":0,"temporary_token":null,"wallet_balance":null,"token":{"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYWUyMTdiNDJjN2VmOGFmMzM4ODMwOGUwMTU0MzMwYTE3NGMzN2YxZmExMzc2NDJmNTVhZGFmMTg2YjhkMWZlM2FkZjZlYmNiNjA1NTE0MjYiLCJpYXQiOjE2ODQ4NjI1MTUuNjI3MTYyLCJuYmYiOjE2ODQ4NjI1MTUuNjI3MTY0LCJleHAiOjE3MTY0ODQ5MTUuNjIzMzQyLCJzdWIiOiIxMCIsInNjb3BlcyI6W119.sLEZJed-iJXK4Gt7ClUcTcq-gPvGyYEkEvMHdtNDQA34iAULnfxdamU2perpzJIqfOS49cilUKWH6p857F8kAgi5v4mNEfkMxnOAqZXM-CjTrlnOl7UlgRoaXzf3pLdWTnBbD6SRkjGW8ASnpT55JdmoaQwMuSAkTSC_IsVcn6rt2pfVqf666FbTvy288M-tjKvYq0FtLE3DGluBqTuhILdWwiExw61JCg-90Lm60uGTfLF8dbOzwRVPb1Cb1DKrnUdcxZluf80Rl3o1_jTeayQ1Co2myuqNj2poTlm_bmE14Pm377IKYe7rJJj2B5rfdOId15rogDycZxHNO3iRVyIWfe7qpi-57jxiC-iZ-467BtQsqk-r8mamk-sywa5-BlxGYp7Fcr-dz44lijo2PK0P_e3_pwxi-4PttAS4ArM7OFsDDH4Nda4gOF9WkRd9-DGUwJrALNQun7WZwePTnOTj3e2XFBSxXuJR0oOAGhAmuD6Cs4vcRb4vdhX5xqB9K4b3N0sxIqeU7_r5R4smUjhSAAsAP8Ycg36c1MQzk97FrlOhPntivZzBerBAiS7yLVy7YfLx1aUKeHcdGPuIu_yWJz0VzDdFNc0vV3z6FghaA_b_LS07pnpR199r93QEExI9ntaJPAeSuCJvNTOv2xyHHBIAsbkMPYXxq69Utu0","token_type":"Bearer","expires_at":"2023-05-25 10:36:46"}}',
            "hs-navbar-vertical-aside-mini": "false",
        };
        localStorage.setItem("userLogged", JSON.stringify(userLogged));
        window.location.reload(true);
    };
    const [open, setOpen] = useState(false);
    return (
        <div className="relative">
            <button
                onClick={() => setOpen(!open)}
                className="hover:scale-110 transition-all duration-300 inline-block w-full px-4 py-2 text-center  rounded-md shadow text-[#06283D]    "
            >
                Log in
            </button>
            <motion.ul
                animate={open ? "open" : "closed"}
                variants={variants}
                className={`absolute md:right-0 z-50  w-48 py-2 mt-2 bg-gray-100/90 backdrop-blur-xl text-[#06283D]  rounded-md shadow-xl ${
                    open ? "block " : "hidden"
                }`}
            >
                <a
                    href={API_URL + "/customer/auth/login"}
                    className="flex cursor-pointer gap-2 w-full items-center px-3 py-2 text-sm hover:text-[#47B5FF]"
                >
                    As a costumer
                </a>
                <a
                    href={API_URL + "/seller/auth/login"}
                    className="flex cursor-pointer gap-2 w-full items-center px-3 py-2 text-sm hover:text-[#47B5FF]"
                >
                    As a seller
                </a>
            </motion.ul>
        </div>
    );
}

export default LogInButton;
