import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch} from "react-redux";
import { FiShoppingBag } from "react-icons/fi";
import { AiOutlineHeart } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {API_URL} from "../env";

import {
  useGetRelatedProductsQuery,
  useGetSingleProductQuery,
} from "../reduxtk/productsApi";
import ReactStarRating from "react-star-ratings-component";
import ProductsList from "../components/products/ProductsList";
import BeatLoader
 from "react-spinners/BeatLoader";
import { useAddToCartMutation, useGetCartItemsQuery } from "../reduxtk/cartApi";
import { useAddWishListItemMutation, useGetWishListItemsQuery } from "../reduxtk/wishListApi";
function ProductsDetails() {
  const { slug } = useParams();
  const { data: selectedProduct, isLoading:isLoadingProduct, isSuccess:isSuccessProduct} = useGetSingleProductQuery(slug);
  const { data: relatedProducts,isLoading:isLoadingRelatedProducts, isSuccess:isSuccessRelatedProducts } = useGetRelatedProductsQuery( selectedProduct?.id );
   const { data: cartItems,refetch:cartRefetch } = useGetCartItemsQuery();
  const {data :wishlistItems,refetch:wishlistRefetch } = useGetWishListItemsQuery()
  const [addToWishlist,{isSuccess:wishlistSuccess} ] =useAddWishListItemMutation();
  const [addToCart , {isSuccess:cartSuccess}] = useAddToCartMutation();
  const relatedProductsList = relatedProducts?.products;
  const rating = parseInt(selectedProduct?.average_review);
  const dispatch = useDispatch();
  const AddToCart = () => {
      let body = new FormData();
    body.append("id", selectedProduct?.id);
    body.append("quantity", selectedProduct?.minimum_order_qty);
    addToCart(body);
     toast.success("Item added to cart successfully");
  };
  const AddToLikes = () => {
     addToWishlist(selectedProduct?.id);
    toast.success("Item added to wishlist successfully");
  };
  useEffect(()=>{
    window.scrollTo(0, 0);
    cartRefetch()
     wishlistRefetch()
  } , [wishlistSuccess,cartSuccess])
  const imageCover = selectedProduct?.images[0];
  return (
    <div className="text-gray-600 body-font py-12 sm:py-8 lg:py-20 mx-5 ">
      {isLoadingProduct && isLoadingRelatedProducts ? (
         <div className="flex items-center justify-center  ">
          <BeatLoader

        color="#47B5FF"
        
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
        </div>
      ) : (
        <div>
        <div className=" container max-w-5xl flex md:flex-row flex-col   mx-auto items-center justify-between ">
          <div className=" flex mt-0 items-start justify-end   md:mb-0 mb-4 gap-10 ">
            <div className="flex-col items-start justify-end space-y-4 ">
              {selectedProduct?.images.map((image) => (
                <div className="flex border-2 shadow-sm bg-gray-400 rounded-md ">
                  <img
                    src={ API_URL + `/storage/app/public/product/${image}`}
                    alt=""
                    className="object-cover hover:opacity-75 duration-300  object-center w-28  "
                  />
                </div>
              ))}
            </div>
            <div className="flex w-100">
              <img
                src={ API_URL + `/storage/app/public/product/${imageCover}`}
                alt=""
                className="object-cover   object-center  "
              />
            </div>
          </div>
          <div className="md:basis-1/2 flex  lg:flex-grow  lg:pr-24 md:pr-16  flex-col md:items-start md:text-left mb-10 md:mb-0 items-center text-center gap-1 mx-10 ">
            <h1 className="text-black text-lg lg:text-xl font-bold">
              {selectedProduct?.name}
              {selectedProduct?.id}
            </h1>

            <p className="text-red-700 text-md md:text-lg font-bold  ">
              <span className="text-md font-semibold">$</span>
            {selectedProduct?.unit_price - selectedProduct?.discount}
            {selectedProduct?.discount > 0 ? (
              <span className="text-md ml-2 text-gray-500 line-through font-semibold">
                ${selectedProduct?.unit_price}
              </span>
            ) : null}
            </p>
            <ReactStarRating
              numberOfStar={5}
              numberOfSelectedStar={rating}
              colorFilledStar="#FFAD33"
              colorEmptyStar="#CDDFF2"
              starSize="20px"
              spaceBetweenStar="2px"
              disableOnSelect={true}
            />

            <p className="text-[#68707D] text-sm ">
              {selectedProduct?.details}
            </p>

            <div className="flex py-4 gap-2.5">
              <button
                onClick={AddToCart}
                className=" bg-[#47B5FF]  inline-flex  focus-visible:ring  text-white text-md  font-semibold text-center rounded-md outline-none transition duration-100 px-4 py-2"
              >
                Add To Cart
                <FiShoppingBag size={18} className="mt-1 mx-2" />
              </button>

              <button
                onClick={AddToLikes}
                className="inline-block bg-gray-200 hover:bg-gray-300  rounded-lg transition duration-100 px-2 border-2 border-gray-400"
              >
                <AiOutlineHeart size={22} className="text-[#06283D]" />
              </button>
            </div>
          </div>
          
        </div>
           <div className="container text-start flex-col items-center justify-center space-y-5  max-w-6xl mx-auto  py-10 ">
        <div className="flex items-center justify-between gap-4">
          <div className="flex gap-5">
            <div className="bg-[#47B5FF] w-5 rounded-sm"></div>
            <h1 className="font-semibold text-[#47B5FF] text-lg">This Month</h1>
          </div>

          <Link
            to="/products"
            className="py-3 flex items-center justify-center w-40 text-md font-medium rounded-sm text-white bg-gradient-to-br from-[#06283D] to-[#0a486e] focus:outline-none focus:ring-2 focus:ring-offset-2 "
          >
            View All
          </Link>
        </div>
        {relatedProductsList?.length > 0 ? (
          <ProductsList data={relatedProductsList} />
        ) : (
          <h1 className="text-center text-gray-400 tracking-wider text-lg font-semibold ">
            No Products Available
          </h1>
        )}
      </div>
      </div>
        
      )}

   
    </div>
  );
}

export default ProductsDetails;
