import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { FaCcVisa, FaCcPaypal } from "react-icons/fa";
import Paypal from "../assests/paypal.png";
import Visa from "../assests/Visa.png";

import { Link } from "react-router-dom";
function CheckOut() {
  const [state, setstate] = useState();
  const [selected, setSelected] = useState("delivery");
  const changeHandler = (e) => {
    setSelected(e.target.value);
  };
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const [successMsg, setSuccessMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setSuccessMsg("User Check Out is successful.");
    reset();
  };
  return (
    <section className=" body-font py-12 sm:py-8 lg:py-20 max-w-6xl mx-auto">
      <div className="container mx-auto md:text-start text-center ">
        <h1 className="text-start mx-4 font-medium text-2xl tracking-widest ">
          Billing Details
        </h1>
      </div>
      <form
        className=" container flex md:flex-row flex-col py-10  mx-auto  justify-between "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="basis-1/2  mx-4">
          {" "}
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">First Name</label>
            <input
              type="text"
              {...register("firstname", {
                required: "First Name is required.",
              })}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
            {errors.firstname && (
              <p className="max-w-md text-red-700  px-1  text-sm font-normal">
                {errors.firstname.message}
              </p>
            )}
          </div>
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">Last Name</label>
            <input
              type="text"
              {...register("lastname", {
                required: "Last Name is required.",
              })}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
            {errors.lastname && (
              <p className=" max-w-md text-red-700  px-1 text-sm font-normal">
                {errors.lastname.message}
              </p>
            )}
          </div>
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">Company Name</label>
            <input
              type="text"
              {...register("companyname")}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
          </div>
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">Street Address</label>
            <input
              type="text"
              {...register("streetAddress", {
                required: "Street Address is required.",
              })}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
            {errors.streetAddress && (
              <p className=" max-w-md text-red-700  px-1 text-sm font-normal">
                {errors.streetAddress.message}
              </p>
            )}
          </div>
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">Apartment, floor, etc. (optional)</label>
            <input
              type="text"
              {...register("apartment")}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
          </div>
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">Town/City*</label>
            <input
              type="text"
              {...register("town", {
                required: "Town/City is required.",
              })}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
            {errors.town && (
              <p className=" max-w-md text-red-700  px-1 text-sm font-normal">
                {errors.town.message}
              </p>
            )}
          </div>
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">Phone Number</label>
            <input
              type="text"
              {...register("phoneNumber", {
                required: true,
                validate: {
                  matchPattern: (value) => /^\d*\.?\d*$/.test(value),
                },
              })}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
            {errors.phoneNumber?.type === "required" && (
              <p className="max-w-md text-red-700  px-1 text-sm font-normal">
                Phone Number is required
              </p>
            )}

            {errors.phoneNumber?.type === "matchPattern" && (
              <p className="max-w-md text-red-700  px-1 text-sm font-normal">
                Phone Number should contain only digits
              </p>
            )}
          </div>
          <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
            <label className="">Email Address</label>
            <input
              type="text"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  message: "Email is not valid.",
                },
              })}
              className=" bg-[#06283d29] rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
            />
            {errors.email && (
              <p className="max-w-md text-red-700  px-1 text-sm font-normal">
                {errors.email.message}
              </p>
            )}
          </div>
          {successMsg && (
            <p className="text-start text-green-500 text-md">{successMsg}</p>
          )}
        </div>

        <div className="md:basis-1/2 flex flex-col md:mb-0 mb-4   text-start  mx-10 my-10 space-y-5   ">
          <div className="flex items-center justify-between  w-full border-b-2 py-2 ">
            <h1 className=" uppercase text-base font-medium">Subtotal :</h1>
            <div>{totalAmount} $</div>
          </div>
          <div className="flex items-center justify-between  w-full border-b-2 py-2 ">
            <h1 className=" uppercase text-base font-medium">
              Total Quantity :
            </h1>
            <div>{totalQuantity} items</div>
          </div>
          <div className="flex items-center justify-between  w-full border-b-2 py-2   ">
            <h1 className=" uppercase text-base font-medium">Shipping :</h1>
            <div>0 $</div>
          </div>
          <div className="flex items-center justify-between  w-full   ">
            <h1 className=" uppercase text-base font-semibold">Total :</h1>
            <div>{totalAmount} $</div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div className="flex items-center">
              {" "}
              <input
                checked={selected === "bank"}
                onChange={changeHandler}
                id="bank"
                type="radio"
                value="bank"
                name="payment"
                className="w-5 h-5  bg-gray-100 border-gray-300  "
              />
              <label htmlFor="bank" className="ml-2 text-md font-medium">
                Bank
              </label>
            </div>
            <img src={Visa} alt="" className=" w-14 mt-1" />
          </div>
          <div
            className="flex items-center justify-between 
           "
          >
            <div className="flex items-center">
              <input
                checked={selected === "paypal"}
                onChange={changeHandler}
                id="paypal"
                type="radio"
                value="paypal"
                name="payment"
                className="w-5 h-5  bg-gray-100 border-gray-300  "
              />
              <label htmlFor="paypal" className="ml-2 text-md font-medium">
                Paypal
              </label>
            </div>
            <img src={Paypal} alt="" className=" w-20" />
          </div>
          <div className="flex items-center ">
            <input
              checked={selected === "delivery"}
              onChange={changeHandler}
              id="delivery"
              type="radio"
              value="delivery"
              name="payment"
              className="w-5 h-5  border-gray-300"
            />
            <label htmlFor="delivery" className="ml-2 text-md font-medium">
              Cash on delivery
            </label>
          </div>
          <div
            className={`flex  flex-col p-4 ${
              selected === "bank" ? "flex" : "hidden"
            }`}
          >
            <h1 className=" tracking-wider font-medium">Payment Details</h1>
            <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
              <input
                placeholder="Enter Name On Card"
                type="text"
                {...register("cardname", {
                  required: selected === "bank" ? true : false,
                })}
                className=" bg-transparent appearance-none  block w-full px-3 py-2  border-b-2  placeholder-black/50 text-black/70  focus:outline-[#47B5FF]  sm:text-md"
              />
            </div>
            <div className="text-start my-5 space-y-2 text-gray-400 font-medium">
              <input
                placeholder="Card Number "
                type="text"
                {...register("cardnumber", {
                  required: selected === "bank" ? true : false,
                })}
                className=" bg-transparent appearance-none  block w-full px-3 py-2  border-b-2  placeholder-black/50 text-black/70  focus:outline-[#47B5FF]  sm:text-md"
              />
            </div>
            <div className="text-start mt-5 space-y-2 text-gray-400 font-medium">
              <input
                placeholder="CVV"
                type="text"
                {...register("cvv", {
                  required: selected === "bank" ? true : false,
                })}
                className="  bg-transparent appearance-none  block w-full px-3 py-2  border-b-2  placeholder-black/50 text-black/70  focus:outline-[#47B5FF]  sm:text-md"
              />
            </div>
          </div>

          <div className="py-10 flex items-center justify-center w-full">
            <button
              type="submit"
              className="flex items-center justify-center rounded-sm  bg-[#06283D]  cursor-pointer px-6 py-3 text-base font-medium text-white shadow-sm w-full"
            >
              Place order
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default CheckOut;
