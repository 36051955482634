import React from "react";
import Photo1 from "../../assests/photo_1.jpg";
import Photo2 from "../../assests/photo_2.jpg";
import Photo3 from "../../assests/photo_3.jpg";
import { motion } from "framer-motion";
function PageThree() {
  return (
    <div className=" mt-20 text-[#06283D]">
      <div className="flex flex-col md:items-start items-center md:ml-36 ml-0 mt-20 ">
        <h1 className="title-font sm:text-3xl text-2xl font-bold drop-shadow-2xl shadow-white tracking-widest ">
          OUR NEWS
        </h1>
        <p className="text-xl font-semibold mt-2">Lorem ipsum dolor sit amet</p>
      </div>
      <div className="md:h-40   bg-gradient-to-r from-[#47B5FF] mr-60 -z-50 -mt-8 "></div>
      <div className="container mx-auto md:-mt-32 md:py-20 py-24">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.7 }}
          className="flex flex-wrap "
        >
          <div className="lg:w-1/3 lg:mb-0 mb-10 px-20">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="hover:scale-110 transition-all duration-300 h-40 w-60 mb-4 rounded-md shadow-lg shadow-gray-800/60 object-cover object-center  inline-block "
                src={Photo1}
              />
              <p className="leading-relaxed font-medium">
                Lorem ipsum dolor sit amet,consectetuer adipiscingsit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laor
              </p>
            </div>
          </div>
          <div className="lg:w-1/3 lg:mb-0 mb-10 px-20">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="hover:scale-110 transition-all duration-300 h-40 w-60 mb-4 rounded-md shadow-lg shadow-gray-800/60 object-cover object-center  inline-block "
                src={Photo2}
              />
              <p className="leading-relaxed font-medium">
                Lorem ipsum dolor sit amet,consectetuer adipiscingsit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laor
              </p>
            </div>
          </div>
          <div className="lg:w-1/3 lg:mb-0 mb-10 px-20">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="hover:scale-110 transition-all duration-300 h-40 w-60 mb-4 rounded-md shadow-lg shadow-gray-800/60 object-cover object-center  inline-block "
                src={Photo3}
              />
              <p className="leading-relaxed font-medium">
                Lorem ipsum dolor sit amet,consectetuer adipiscingsit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laor
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default PageThree;
