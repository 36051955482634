import React from "react";
import { Link } from "react-router-dom";
import Laptop from "../assests/laptop.png";
import Photo1 from "../assests/features.png";
import Photo2 from "../assests/benefits.png";
import Photo3 from "../assests/visuals.png";
import Photo4 from "../assests/updates.png";
function BecomeSeller() {
  return (
    <section className="text-gray-600 body-font  ">
      <div className="bg-gradient-to-t from-[#257A85] via-[#1C4866] to-[#06283D]">
        <div className="container max-w-6xl mx-auto  flex px-5 py-24 md:flex-row flex-col items-center ">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center tracking-widest px-10 ">
            <h1 className="title-font sm:text-3xl text-2xl mb-4 font-semibold text-white ">
              Smile Brighter with Dashboard Dental Onlineshop
            </h1>
            <p className="mb-8 leading-relaxed text-lg text-white/70">
              Effortlessly manage your dental supplies and orders with one
              central platform. <br />
              Join the dental revolution with Dashboard Dental Onlineshop!
            </p>
            <div className="flex justify-center">
              <Link className="hover:scale-110 transition-all duration-300 inline-flex shadow-md text-white uppercase  bg-[#06283D] border-0 py-3 px-10 focus:outline-none rounded text-md font-medium">
                join now
              </Link>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className=" object-contain object-center rounded"
              alt="hero"
              src={Laptop}
            />
          </div>
        </div>
      </div>
      <div className=" container py-28 lg:max-w-5xl max-w-7xl flex flex-wrap mx-auto text-center ">
        <div className="p-4 w-full  md:w-1/4 sm:w-1/2 mt-5">
          <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg text-white shadow-black/50 flex items-center flex-col px-4 min-h-full py-4 rounded-lg hover:scale-110 transition-all duration-300">
            <img src={Photo1} alt="" className="h-20 w-20 -mt-14" />
            <h1 className="font-bold uppercase mb-2 tracking-wider">
              features
            </h1>
            <p className="leading-relaxed  ">
              Easy order management. Real-time inventory updates. Delivery
              tracking. Supplier collaboration. Customizable dashboards.
              Mobile-friendly design.
            </p>
          </div>
        </div>
        <div className="p-4 w-full md:w-1/4 sm:w-1/2 mt-5">
          <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg text-white shadow-black/50 flex items-center flex-col min-h-full px-2  py-4 rounded-lg hover:scale-110 transition-all duration-300">
            <img src={Photo2} alt="" className="h-20 w-20 -mt-14" />
            <h1 className="font-bold uppercase mb-2 tracking-wider">
              benefits
            </h1>
            <p className="leading-relaxed  ">
              Increased efficiency and productivity. Improved communication with
              suppliers. Enhanced patient satisfaction. Better control over your
              dental supply chain.
            </p>
          </div>
        </div>
        <div className="p-4 w-full md:w-1/4 sm:w-1/2 mt-5 ">
          <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg text-white shadow-black/50 flex items-center min-h-full flex-col px-2  py-4 rounded-lg hover:scale-110 transition-all duration-300">
            <img src={Photo3} alt="" className="h-20 w-20 -mt-14" />
            <h1 className="font-bold uppercase mb-2 tracking-wider">Visuals</h1>
            <p className="leading-relaxed  ">
              A graphic of a dashboard with charts, graphs and real-time data.
            </p>
          </div>
        </div>
        <div className="p-4 w-full  md:w-1/4 sm:w-1/2 mt-5">
          <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg text-white shadow-black/50 flex items-center min-h-full flex-col px-2  py-4 rounded-lg hover:scale-110 transition-all duration-300">
            <img src={Photo4} alt="" className="h-20 w-20 -mt-14" />
            <h1 className="font-bold uppercase mb-2 tracking-wider">Updates</h1>
            <p className="leading-relaxed  min-h-30">
              A smartphone or tablet showing the mobile app interface Images of
              dental products and supplies
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BecomeSeller;
