import React from "react";
import Logo from "../../assests/Oxy_Markt Logo_ White png 1.png";
import { BsApple, BsGooglePlay } from "react-icons/bs";
import { SiGoogleplay } from "react-icons/si";
function Footer() {
  return (
    <footer className="text-white bg-gradient-to-b from-[#06283D] to-[#47B5FF] ">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className=" lg:w-1/4 md:w-full sm:w-2/4 flex flex-col   mx-auto text-center space-y-4  ">
          <img src={Logo} alt="logo" className="md:-mt-10" />
          <h1 className=" font-medium">Download Oxymarkt Application</h1>
          <div className="flex gap-4 items-center justify-center">
            <div className="flex rounded-md gap-2 font-medium  bg-white cursor-pointer px-3 py-1.5 text-sm text-black shadow-md  hover:scale-105 hover:shadow-lg duration-300">
              <BsApple size={35} />
              Download On App Store
            </div>
            <div className="flex rounded-md gap-2 font-medium  bg-black cursor-pointer px-3 py-1.5 text-sm text-white shadow-md  hover:scale-105 hover:shadow-lg duration-300">
              <SiGoogleplay size={35} className="text-white" />
              Download On Google Play
            </div>
          </div>
        </div>
        <div className="flex-grow flex flex-wrap gap-10 lg:gap-0  md:text-left text-center order-first mb-10">
          <div className="lg:w-1/3 w-full px-4 ">
            <ul className="flex-col space-y-3">
              <li className=" font-medium text-md uppercase">contact us</li>
              <li className=" text-md ">
                Shams Business Center Al Messaned,Shariah, UAE
              </li>
              <li className=" text-md ">info@oxymarkt.com</li>
              <li className=" text-md ">+971 562 433 708</li>
            </ul>
          </div>
          <div className="lg:w-1/3  w-full px-4">
            <ul className="flex-col space-y-3">
              <li className="  text-md ">About us</li>
              <li className="  text-md ">Customer reviews</li>
              <li className=" text-md ">Privacy and policies</li>
            </ul>
          </div>
          <div className="lg:w-1/3 w-full px-4">
            <ul className="flex-col space-y-3">
              <li className="text-md ">Return and refund</li>
              <li className=" text-md ">Shipping and delivery</li>
              <li className=" text-md ">Become our supplier</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
