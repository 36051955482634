import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {API_URL} from "../env";
export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + "/api/v1",
    prepareHeaders: (headers, { getState }) => {
      function getAccessToken() {
        let user = localStorage.getItem("user");
        return user ? JSON.parse(user)["token"]["access_token"] : null;
      }
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrdersList: builder.query({ query: () => "customer/order/list" }),
    //cancelOrderList: builder.query({ query: (order_id) => `order/cancel-order?order_id=${order_id}` }),
    cancelOrder : builder.mutation({
      query: (order_id) => ({
        url: `order/cancel-order?order_id=${order_id}`,
        method:"GET"
      }),
    }),
  
  }),
});
export const {
  useGetOrdersListQuery,
  useCancelOrderMutation
 
} = ordersApi;
