import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function CheckOutCart({ onCartToggle , totalPrice }) {
  
  return (
    <div className="flex flex-col md:flex-row bg-white px-5 py-4 items-center gap-2  my-4  ">
      <div className="mt-6 md:w-2/3 w-full text-sm  items-start px-6  ">
        <h1 className="border-b border-gray-400 py-2 tracking-wider text-lg font-semibold uppercase">
          Cart Total
        </h1>
        
        <div className="flex mt-4 items-center justify-between font-medium text-base w-full">
          <h1 className="">Total Price </h1>
          <h1 className=" ">{totalPrice} $</h1>
        </div>
      </div>
      <div className="md:w-1/3 w-full items center ">
        <div className="mt-6 text-center ">
          <Link
            onClick={onCartToggle}
            to="/checkout"
            className="inline-block rounded-sm  bg-[#47B5FF] cursor-pointer w-full px-3 py-3 text-sm font-normal text-white shadow-sm  hover:bg-[#308EAD] transition-all ease-in-out duration-300 "
          >
            Proceed to checkout
          </Link>
        </div>
        <div className="mt-6 text-center ">
          <Link
            onClick={onCartToggle}
            className="inline-block  rounded-sm border border-gray-600 cursor-pointer px-3 py-3 text-sm font-normal  shadow-sm  w-full"
          >
            Return to shop
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CheckOutCart;
