import React from "react";
import Photo from "../../assests/IMG_0337 2.JPG";
import { motion } from "framer-motion";
function PageFour() {
  return (
    <section className="text-gray-600 body-font bg-gradient-to-r from-[#47B5FF] to-[#06283D]">
      <div className="container max-w-7xl mx-auto flex px-5 py-24 md:mt-28 mb-20 md:flex-row flex-col items-center">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.7 }}
          className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center text-white"
        >
          <h1 className="tracking-widest title-font sm:text-3xl text-2xl mb-4 font-bold ">
            OUR MISSION
          </h1>
          <p className="text-xl leading-relaxed ">
            At Oxymarkt, our mission is to drive innovation in healthcare and
            make it accessible to all. We are dedicated to being the top-tier
            provider of medical equipment in Germany. Our ultimate goal is to
            enhance the well-being of individuals by offering safe, effective,
            and high-quality products manufactured under the latest facilities
            and industry standards. We may be part of a larger organization, but
            we're always close to our clients and communities.
          </p>
        </motion.div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="hover:scale-110 transition-all duration-300 object-cover object-center rounded shadow-md shadow-black/50"
            alt="hero"
            src={Photo}
          />
        </div>
      </div>
    </section>
  );
}

export default PageFour;
