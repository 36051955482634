import React from "react";
import { HiOutlineArrowLeft } from "react-icons/hi2";
function LikesCount({ onLikesToggle, count }) {
  return (
    <div className="flex items-center justify-between px-6 py-6 sticky w-full">
      <div className="flex items-center cursor-pointer">
        <HiOutlineArrowLeft onClick={onLikesToggle} size={28} color="06283D" />

        <span className="px-4 text-base font-medium">Whish List ({count})</span>
      </div>
    </div>
  );
}

export default LikesCount;
