import React from "react";

function CartEmpty() {
  return (
    <div className="flex items-center justify-center h-screen text-center  text-gray-400 font-medium">
      Your Cart is empty
    </div>
  );
}

export default CartEmpty;
