import React from "react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setOpenCart } from "../../reduxtk/slices/CartSlice";
import { useGetCartItemsQuery } from "../../reduxtk/cartApi";
function CartButton() {
  const { data: cartItems } = useGetCartItemsQuery();
  const count = cartItems? cartItems.length : 0;
  const dispatch = useDispatch();
  const onCartToggle = () => {
    dispatch(setOpenCart({ cartState: true }));
  };
  return (
    <div
      onClick={onCartToggle}
      className="relative active:scale-110 transition-all duration-200 inline-flex  mx-1 mb-2  cursor-pointer lg:inline-block"
    >
      <div className="t-0 absolute left-4">
        <p className="flex h-2 w-2 items-center justify-center rounded-full bg-[#47B5FF] p-3 text-xs text-white shadow-sm shadow-[#47B5FF]">
          {count}
        </p>
      </div>
      <HiOutlineShoppingBag
        size={30}
        color="#06283D"
        className="mt-3  transition-all duration-300"
      />
    </div>
  );
}

export default CartButton;
