import { Link } from "react-router-dom";

function Header({ heading, paragraph, linkName, linkUrl = "#" }) {
  return (
    <div className="mt-4 ">
      <h2 className="tracking-widest text-center text-3xl font-bold text-white">
        {heading}
      </h2>
      <p className="mt-4 text-center text-md text-gray-800 ">
        {paragraph}{" "}
        <Link
          to={linkUrl}
          className="font-medium text-white/70 hover:text-[#47B5FF]"
        >
          {linkName}
        </Link>
      </p>
    </div>
  );
}
export default Header;
