import React from "react";
import Service1 from "../../assests/Services.png";
import Service2 from "../../assests/Services (1).png";
import Service3 from "../../assests/Services (2).png";
function Services() {
  return (
    <section className="text-gray-600 body-font my-10 p-16">
      <div className="container px-5  mx-auto max-w-4xl">
        <div className="flex flex-wrap items-center justify-center -m-4">
          <div className="lg:w-1/3 w-full lg:mb-0 mb-6 p-4">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="w-20 h-20 mb-6 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={Service1}
              />

              <h2 className="text-black font-semibold title-font tracking-wider text-sm">
                FREE AND FAST DELIVERY
              </h2>
              <p className="text-gray-800">
                Free delivery for all orders over $140
              </p>
            </div>
          </div>
          <div className="lg:w-1/3 w-full lg:mb-0 mb-6 p-4">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="w-20 h-20 mb-6 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={Service2}
              />

              <h2 className="text-black font-semibold title-font tracking-wider text-sm">
                FREE AND FAST DELIVERY
              </h2>
              <p className="text-gray-800">Friendly 24/7 customer support</p>
            </div>
          </div>
          <div className="lg:w-1/3 w-full lg:mb-0 p-4">
            <div className="h-full text-center">
              <img
                alt="testimonial"
                className="w-20 h-20 mb-6 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={Service3}
              />

              <h2 className="text-black font-semibold title-font tracking-wider text-sm">
                MONEY BACK GUARANTEE
              </h2>
              <p className="text-gray-800">We return money within 30 days</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
