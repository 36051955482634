import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../assests/Oxy_Markt-_Logo_-09 1.png";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileButton from "../buttons/ProfileButton";
import SignUpButton from "../buttons/SignUpButton";
import LogInButton from "../buttons/LogInButton";
import LikesButton from "../buttons/LikesButton";
import CartButton from "../buttons/CartButton";
import { HiMenuAlt3, HiX } from "react-icons/hi";

function Navbar() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [navbar, setNavbar] = useState(false);
  const [navState, setNavState] = useState(false);
  const onNavScroll = () => {
    if (window.scrollY > 30) {
      setNavState(true);
    } else {
      setNavState(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onNavScroll);

    return () => {
      window.removeEventListener("scroll", onNavScroll);
    };
  }, []);

  const logo = (
    <Link to="/">
      <img src={Logo} alt="" className="w-40" />
    </Link>
  );

  return (
    <header
      className={
        !navState
          ? "opacity-100 z-50 bg-slate-100 "
          : "fixed  w-full z-50 duration-300 "
      }
    >
      <nav className="font-medium w-full shadow-lg bg-white/50 duration-500 backdrop-blur-lg  ">
        <div className="justify-between px-4 mx-auto lg:max-w-screen-2xl lg:items-center lg:flex ">
          <div>
            <div className="flex items-center justify-between py-3 lg:block">
              {logo}

              <div className="lg:hidden ">
                {currentUser ? <ProfileButton /> : null}
                <CartButton />
                <LikesButton />

                <button
                  className="p-2 text-[#06283D] rounded-md outline-none"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? <HiX size={33} /> : <HiMenuAlt3 size={33} />}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0  ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className=" items-center justify-center   text-[#06283D] space-y-6 lg:flex lg:space-x-10 lg:space-y-0">
                <li className="hover:text-[#47B5FF] ">
                  <Link to="/">Home</Link>
                </li>
                <li className="hover:text-[#47B5FF] ">
                  <Link to="/productpage">Products</Link>
                </li>
                <li className=" hover:text-[#47B5FF]">
                  <Link to="/becomeseller">Become a seller</Link>
                </li>
                <li className="hover:text-[#47B5FF] ">
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
              {!currentUser ? (
                <div className="mt-3 space-y-2 lg:hidden ">
                  <LogInButton />
                  <SignUpButton />
                </div>
              ) : null}
            </div>
          </div>
          <div className="hidden space-x-2 lg:inline-flex items-center gap-3">
            {currentUser ? (
              <ProfileButton />
            ) : (
              <div className="flex space-x-2">
                <LogInButton />
                <SignUpButton />
              </div>
            )}
            <CartButton />
            <LikesButton />
            <div className="inline-flex font-normal text-[#06283D] rounded-md shadow  ">
              <select className="px-1 py-1.5 border-spacing-1 rounded-md bg-white/50">
                <option>EN</option>
              
              </select>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
