import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {API_URL} from "../env";
export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + "/api/v1/",
  }),
  endpoints: (builder) => ({
    getLatestProducts: builder.query({ query: () => "products/latest" }),
    getTopRatedProducts: builder.query({ query: () => "products/best-sellings" }),
    getRelatedProducts: builder.query({
      query: (id) => `products/related-products/${id}`,
    }),
    getSingleProduct: builder.query({ query: (slug) => `products/details/${slug}` }),
    getHomeCategories: builder.query({ query: () => "products/home-categories" }),
    getAllCategories: builder.query({ query: () => "categories"})
  }),
});

export const {
  useGetLatestProductsQuery,
  useGetTopRatedProductsQuery,
  useGetRelatedProductsQuery,
  useGetSingleProductQuery,
  useGetHomeCategoriesQuery,
  useGetAllCategoriesQuery
} = productsApi;
