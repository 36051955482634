import React,{useEffect, useState} from "react";
import { useCancelOrderMutation, useGetOrdersListQuery } from "../reduxtk/ordersApi";
import BeatLoader
 from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Orders() {

  
  
  const {data : ordersList,refetch,isLoading,isSuccess} =useGetOrdersListQuery();
  const [cancleOrder , {isSuccess:isCancleSuccess}] = useCancelOrderMutation();
  const [isRefetch , setIsRefetch] = useState(false);
  const cancel = (event)=>{
    const orderId =event.target.value;
    const item = ordersList.find(order => order.id == orderId);
    if(item.order_status !== "canceled"){
      cancleOrder(orderId);
    }
    else{
toast.error("Order can be canceled only when it's pending");
    }
}
 
  useEffect(()=>{
    window.scrollTo(0, 0);

      if(isRefetch){
          refetch();
      }
      setIsRefetch(true);
  } , [isCancleSuccess]);
  
   
  //  useEffect(() => {
  //    refetch();
  // }, [isSuccess])
  
  const date =(isoDateString)=>{
const date = new Date(isoDateString);
const options = { year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = date.toLocaleDateString('en-US', options);
const formattedTime = date.toLocaleTimeString('en-US');
return (formattedDate +" "+formattedTime);
  }
 


  return (
    
    <div className="container mx-auto max-w-6xl h-screen py-10 ">
      {
        isLoading ?
        <div className="flex items-center justify-center min-h-full">
          <BeatLoader

        color="#47B5FF"
        loading={isLoading}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
        </div>
         :null
      }
       
       {
        isSuccess ?  <div className=" flex md:flex-col items-start text-start justify-start w-full  ">
       
        <div className="py-2 align-middle overflow-x-auto inline-block min-w-full sm:px-6 lg:px-8">
          <h1 className="text-xl font-semibold py-8 uppercase tracking-widest text-gray-500">my orders</h1>
          <div className="shadow  border-b border-gray-200 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-[#47B5FF] text-white">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-4 text-left text-sm font-medium  tracking-wider"
                  >
                    Order ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium tracking-wider"
                  >
                    Order Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium tracking-wider"
                  >
                    Total
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium tracking-wider"
                  >
                    Action
                  </th>
                  
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {ordersList?.map(order => (
                  <tr key={order.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                          {order.id}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{date(order.created_at)
}</div>
                      
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {
                        order.order_status ===  "canceled" ?  
                         <span
                        className="px-3 py-1 inline-flex text-xs leading-5
                      font-semibold rounded-full bg-red-500 text-white"
                      >
                        Canceled
                      </span>: null

                      }
                      { 
                      order.order_status ===  "pending" ?  
                        <span
                        className="px-3 py-1 inline-flex text-xs leading-5
                      font-semibold rounded-full bg-blue-500 text-white"
                      >
                        Pending
                      </span>:null
                      }
                      
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap font-medium text-sm text-gray-500">
                      $ {order.order_amount}
                    </td>
                    <td  className="px-6 py-4 whitespace-nowrap text-sm text-left ">
                      <button value={order.id}
                onClick={cancel}  className="bg-[#06283D] text-white rounded-md px-2 py-1">
                        Cancel
                      </button>
                    </td>
                  </tr>
                )).reverse() }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    : null
       }
     </div>
  );
}


export default Orders;
