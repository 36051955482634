import React,{useEffect} from "react";
import { HiOutlineTrash } from "react-icons/hi2";
import { FiShoppingBag } from "react-icons/fi";
import { useGetSingleProductQuery } from "../../reduxtk/productsApi";
import { useDeleteWishListItemMutation, useGetWishListItemsQuery } from "../../reduxtk/wishListApi";
import { useAddToCartMutation, useGetCartItemsQuery } from "../../reduxtk/cartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {API_URL} from "../../env";
function LikesItem({ item }) {
  
  const { data: cartItems,refetch:cartRefetch } = useGetCartItemsQuery();
  const {data :wishlistItems,refetch:wishlistRefetch } = useGetWishListItemsQuery()
  const { data: selectedProduct } = useGetSingleProductQuery(item.slug);
  const [removeFromWishlist,{isSuccess:wishlistSuccess}] = useDeleteWishListItemMutation();
  const [addToCart , {isSuccess:cartSuccess}] = useAddToCartMutation();
  const deleteProduct = () => {
    removeFromWishlist(item.id);
    toast.success("Item removed from wish list successfully");
  };
  const Add = () => {
    let body = new FormData();
    body.append("id", selectedProduct?.id);
    body.append("quantity", selectedProduct?.minimum_order_qty);
    addToCart(body);
    toast.success("Item added to cart successfully");
  };
   useEffect(() => {
     cartRefetch()
     wishlistRefetch()
  }, [cartSuccess,wishlistSuccess])
  const image = selectedProduct?.images[0];
  const AddToCart = () => {};
  return (
    <div className="flex items-center justify-between  w-full px-5 py-5   overflow-hidden  border-b border-glass">
      <div className="h-28 w-32 bg-white flex-shrink-0 border  relative rounded  hover:scale-105 transition-all duration-300 ease-in-out  overflow-hidden">
        <img
          src={ API_URL + `/storage/app/public/product/${image}`}
          alt="img"
          className="mx-auto  max-w-full max-h-full object-cover object-center  p-2"
        />
      </div>
      <div className="ml-10 flex flex-1 flex-col  gap-5">
        <div className="flex-col items-start justify-start leading-none">
          <h1 className="font-medium text-lg text-gray-800">
            {selectedProduct?.name}
          </h1>
          <h1 className="font-medium text-sm text-gray-400">{item.category}</h1>
        </div>
        <div className="flex  items-center justify-start gap-5 ">
          <button
            onClick={Add}
            className=" bg-[#47B5FF]  inline-flex  focus-visible:ring  text-white text-md  font-semibold text-center rounded-lg outline-none transition duration-100 px-4 py-2"
          >
            Add To Cart
            <FiShoppingBag size={18} className="mt-1 mx-2" />
          </button>
        </div>
      </div>

      <div className="grid items-center gap-10">
        <div className="grid items-center justify-center">
          <h1 className="text-lg  text-red-700 font-medium ">
            ${selectedProduct?.purchase_price}
            {selectedProduct?.unit_price > 0 ? (
              <span className="text-md mx-2 text-gray-500 line-through font-semibold">
                ${selectedProduct?.unit_price}
              </span>
            ) : null}
          </h1>
        </div>
        <div className="flex items-center justify-center">
          <button
            onClick={deleteProduct}
            className="  p-1 grid items-center justify-items-center w-6 h-6"
          >
            <HiOutlineTrash size={20} className="text-[#06283D] font-medium" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default LikesItem;
