import React, { useEffect, useState } from "react";
import ProductsList from "../components/products/ProductsList";
import { HiOutlineSearch } from "react-icons/hi";
import _ from "lodash";
import BeatLoader
 from "react-spinners/BeatLoader";
import {
  useGetHomeCategoriesQuery,
  useGetLatestProductsQuery,
  useGetTopRatedProductsQuery,
} from "../reduxtk/productsApi";
import "../components/products/products.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Services from "../components/aboutPage/Services";

function ProductPage() {
  const { data: latestProducts ,isLoading:isLatestProductsLoading} = useGetLatestProductsQuery();
  const { data: topRatedProducts,isLoading:isTopRatedProductsLoading } = useGetTopRatedProductsQuery();
  const { data: allCategories ,isLoading:isHomeCategoriesLoading} = useGetHomeCategoriesQuery();
  const latestProductsArray = latestProducts?.products;
  const [latestproductsData, setLatestProductsData] =
    useState(latestProductsArray);
  const topRatedProductsArray = topRatedProducts?.products;
  const [topRatedProductsData, setTopRatedProductsData] = useState(
    topRatedProductsArray
  );
  const [categoriesData, setCategoriesData] = useState(allCategories);
  useEffect(() => {
    setLatestProductsData(latestProductsArray);
    setTopRatedProductsData(topRatedProductsArray);
    setCategoriesData(allCategories);
  }, [latestProductsArray, topRatedProductsArray, allCategories]);

  const handleSearch = (e) => {
    const searchItem = e.target.value;
    const latestSearchedProducts = latestProductsArray.filter((item) =>
      item.name.toLowerCase().includes(searchItem.toLowerCase())
    );
    const TopRatedSearchedProducts = topRatedProductsArray.filter((item) =>
      item.name.toLowerCase().includes(searchItem.toLowerCase())
    );
    setLatestProductsData(latestSearchedProducts);
    setTopRatedProductsData(TopRatedSearchedProducts);
  };

  const handleFilter = (e) => {
    const category = e.target.value;
    const latestCategoryItems = latestProductsArray.filter((item) =>
      item.category_ids.map((categoryId) => categoryId === category)
    );
    const TopRatedCategoryItems = topRatedProductsArray.filter((item) =>
      item.category_ids.map((categoryId) => categoryId.id === category)
    );
    setLatestProductsData(latestCategoryItems);
    setTopRatedProductsData(TopRatedCategoryItems);
  };

  return (
    <div className="">
      <div className="container max-w-6xl mx-auto  py-10 ">
        <div className="flex text-white md:justify-end justify-center items-center rounded-xl bg-photo mx-10">
          <div className="flex flex-col  gap-20 justify-between md:text-start text-center w-96 py-10 mx-10">
            <h1 className=" text-4xl font-semibold tracking-wider leading-relaxed">
              Up to 50% off on your 1st order
            </h1>
            <div className="flex md:items-end md:justify-end justify-center items-center  gap-2 md:mr-20">
              <Link
                to="/signup"
                className="border-b text-lg font-medium cursor-pointer"
              >
                Signup now
              </Link>
              <BsArrowRight size={22} />
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 flex items-center justify-center">
        <div className=" flex items-center justify-center w-7/12 h-14 rounded-lg focus-within:shadow-lg focus-within:border-[#47B5FF]/50 overflow-hidden border">
          <input
            className="bg-[#06283D]/10 h-full w-full outline-none text-grey-900 text-md   px-3"
            type="search"
            id="search"
            placeholder="What are you looking for?"
            onChange={handleSearch}
          />
          <button
            type="submit"
            className="flex items-center justify-center bg-[#06283D]/10  px-4 py-4"
          >
            <HiOutlineSearch size={20} className="" />
          </button>
        </div>
      </div>
      <div className="container text-start flex-col items-center justify-center space-y-5  max-w-6xl mx-auto  py-10 ">
        <div className="flex gap-4">
          <div className="bg-[#47B5FF] w-5 rounded-sm"></div>
          <h1 className="font-semibold text-[#47B5FF] text-lg">Categories</h1>
        </div>

        <h1 className="text-2xl tracking-wide font-semibold">
          Browse By Category
        </h1>
        {isHomeCategoriesLoading ? (
          <div className="flex items-center justify-center ">
          <BeatLoader

        color="#47B5FF"
        
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
        </div>
        ):<div className="flex items-center min-h-full  py-10 gap-10 px-5">
          <button
            value="all"
            onClick={() => {
              setLatestProductsData(latestProductsArray);
              setTopRatedProductsData(topRatedProductsArray);
            }}
            className="flex rounded-md items-center w-36 justify-center bg-white border-2 active:bg-[#06283D] active:border-[#47B5FF] active:text-white   hover:bg-[#06283D] hover:text-white duration-200  px-4 py-6"
          >
            All
          </button>
          {categoriesData?.map((item) => (
            <button
              key={item.id}
              value={item.id}
              onClick={handleFilter}
              className="flex rounded-md items-center w-48 justify-center bg-white border-2 focus-within:bg-[#06283D] focus-within:border-[#47B5FF] focus-within:text-white   hover:bg-[#06283D] hover:text-white duration-200  px-4 py-6"
            >
              {item.name}
            </button>
          ))}
        </div>
        }
        
      </div>

      <div className="container text-start flex-col items-center justify-center space-y-5  max-w-6xl mx-auto  py-10 ">
        <div className="flex gap-4">
          <div className="bg-[#47B5FF] w-5 rounded-sm"></div>
          <h1 className="font-semibold text-[#47B5FF] text-lg">This Month</h1>
        </div>
        <div className="flex items-center justify-between">
          <h1 className=" text-2xl tracking-wide  font-semibold">
            Best Selling Products
          </h1>
          <Link
            to="/products"
            className="py-3 flex items-center justify-center w-40 text-md font-medium rounded-sm text-white bg-gradient-to-br from-[#06283D] to-[#0a486e] focus:outline-none focus:ring-2 focus:ring-offset-2 "
          >
            View All
          </Link>
        </div>
        {isTopRatedProductsLoading ? ( <div className="flex items-center justify-center ">
          <BeatLoader

        color="#47B5FF"
        
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
        </div>): <ProductsList data={topRatedProductsData} />}
       
      </div>
      <div className="container text-start flex-col items-center justify-center space-y-5  max-w-6xl mx-auto  py-10 ">
        <div className="flex gap-4">
          <div className="bg-[#47B5FF] w-5 rounded-sm"></div>
          <h1 className="font-semibold text-[#47B5FF] text-lg">Our Products</h1>
        </div>

        <h1 className=" text-2xl tracking-wide  font-semibold">
          Explore Our Products
        </h1>
        {isLatestProductsLoading? (<div className="flex items-center justify-center ">
          <BeatLoader

        color="#47B5FF"
        
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
        </div>):<ProductsList data={latestproductsData} />}

        
        <Link
          to="/products"
          className="py-3 flex mx-auto items-center justify-center w-40 text-md font-medium rounded-sm text-white bg-gradient-to-br from-[#06283D] to-[#0a486e] focus:outline-none focus:ring-2 focus:ring-offset-2 "
        >
          View All
        </Link>
      </div>

      <Services />
    </div>
  );
}

export default ProductPage;
