import React from "react";
import "./Page.css";
import Arrow from "../../assests/arrow.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function PageOne() {
  return (
    <section className="w-full bg-pic">
      <div className="bg-gradient-to-t from-[#06283D] flex flex-col min-h-full ">
        <div className="container mx-auto flex px-10 py-24 md:flex-row flex-col items-center min-h-screen">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5 }}
            className="text-white lg:flex-grow lg:w-1/2 lg:pr-24 md:pr-24  flex flex-col lg:items-start md:text-left mb-16 md:mb-0 items-center text-center"
          >
            <h1 className="title-font sm:text-4xl text-3xl font-bold drop-shadow-2xl shadow-white tracking-widest mb-8">
              OUR MOTTO
            </h1>
            <p className="mb-16 leading-relaxed italic font-normal sm:text-2xl text-xl   ">
              “ From classroom to clinic, Oxymarkt has you covered ”
            </p>
            <div className="lg:grid lg:grid-cols-2 lg:gap-5">
              <div className="flex items-center justify-center">
                <Link
                  to={"/about"}
                  className=" hover:scale-110 transition-all duration-300 text-white font-medium bg-[#47B5FF] border-0 py-3 px-14 focus:outline-none shadow-md shadow-[#47B5FF] rounded text-lg"
                >
                  READ MORE
                </Link>
              </div>

              <img src={Arrow} alt="" className=" lg:w-28 w-0" />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default PageOne;
