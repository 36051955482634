import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import messageReducer from "./slices/message";
import CartSlice from "./slices/CartSlice";
import LikesSlice from "./slices/LikesSlice";
import { productsApi } from "./productsApi";
import { wishListApi } from "./wishListApi";
import { cartApi } from "./cartApi";
import { ordersApi } from "./ordersApi";
export const store = configureStore({
  reducer: {
    cart: CartSlice,
    likes: LikesSlice,
    auth: authReducer,
    message: messageReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [wishListApi.reducerPath]: wishListApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [ordersApi.reducerPath]:ordersApi.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      productsApi.middleware,
      wishListApi.middleware,
      cartApi.middleware,
      ordersApi.middleware
    ]),
});
