import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  likesState: false,
  likesItems: [],
  quantity: 0,
};
const LikesSlice = createSlice({
  initialState,
  name: "likes",
  reducers: {
    setOpenLikes: (state, action) => {
      state.likesState = action.payload.likesState;
      document.body.style.overflow = "hidden";
    },
    setCloseLikes: (state, action) => {
      state.likesState = action.payload.likesState;
      document.body.style.overflow = "unset";
    },
    setAddItemToLikes: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.likesItems.find(
        (item) => item.id === newItem.id
      );

      if (!existingItem) {
        const tempProduct = {
          ...newItem,
        };
        state.likesItems.push(tempProduct);
        state.quantity++;

        toast.success("Item Added To Wishlist");
      }
    },
    setClearLikesItems: (state, action) => {
      state.likesItems = [];

      state.quantity = 0;

      localStorage.setItem("likes", JSON.stringify(state.likesItems));
      toast.success("WishList is Cleared");
    },
    deleteItem: (state, action) => {
      const id = action.payload;
      const existingItem = state.likesItems.find((item) => item.id === id);
      if (existingItem) {
        state.likesItems = state.likesItems.filter((item) => item.id !== id);
        state.quantity--;
        state.isFav = false;
      }
      toast.success("Item deleted from whislist");
    },
  },
});
export const {
  setOpenLikes,
  setCloseLikes,
  setAddItemToLikes,
  setClearLikesItems,
  deleteItem,
} = LikesSlice.actions;
export const selectLikesState = (state) => state.likes.likesState;
export const selectLikesItems = (state) => state.likes.likesItems;

export default LikesSlice.reducer;
