import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {API_URL} from "../../env";
const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "50%" },
};

function SignUpButton() {
  const [open, setOpen] = useState(false);
  return (
    <div className="relative">
      <button
        onClick={() => setOpen(!open)}
        className="hover:scale-110  transition-all duration-300 inline-block w-full px-4 py-2 text-center text-white  bg-gradient-to-br from-[#06283D] to-[#0B4569] rounded-md shadow"
      >
        Signup
      </button>
      <motion.ul
        animate={open ? "open" : "closed"}
        variants={variants}
        className={`absolute lg:right-0   w-48 py-2 mt-2 bg-gray-100/90 backdrop-blur-xl text-[#06283D]  rounded-md shadow-xl ${
          open ? "block " : "hidden"
        }`}
      >
        <a
          href={API_URL + "/customer/auth/sign-up"}
          className="flex cursor-pointer gap-2 w-full items-center px-3 py-2 text-sm hover:text-[#47B5FF]"
        >
          As a costumer
        </a>
        {/*<a href={API_URL + "/seller/auth/sign-up"}*/}
        {/*    className="flex cursor-pointer gap-2 w-full items-center px-3 py-2 text-sm hover:text-[#47B5FF]">*/}
        {/*  As a seller*/}
        {/*</a>*/}
      </motion.ul>
    </div>
  );
}

export default SignUpButton;
