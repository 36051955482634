import React from "react";
import PageOne from "../components/HomePage/PageOne";
import PageTwo from "../components/HomePage/PageTwo";
import PageThree from "../components/HomePage/PageThree";
import PageFour from "../components/HomePage/PageFour";

function Home() {
  return (
    <div className="bg-[#DFF6FF]">
      <PageOne />
      <PageTwo />
      <PageThree />
      <PageFour />
    </div>
  );
}

export default Home;
