import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartState: false,
  cartItems: [],
  totalAmount: 0,
  totalQuantity: 0,
};
const CartSlice = createSlice({
  initialState,
  name: "cart",
  reducers: {
    setOpenCart: (state, action) => {
      state.cartState = action.payload.cartState;
      document.body.style.overflow = "hidden";
    },
    setCloseCart: (state, action) => {
      state.cartState = action.payload.cartState;
      document.body.style.overflow = "unset";
    },
    setAddItemToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        (item) => item.id === newItem.id
      );
      state.totalQuantity++;
      if (!existingItem) {
        const tempProduct = {
          ...newItem,
          quantity: 1,

          totalPrice: newItem.purchase_price,
        };
        state.cartItems.push(tempProduct);
        toast.success("item add to cart");
      } else {
        existingItem.quantity++;
        existingItem.totalPrice =
          Number(existingItem.totalPrice) + Number(newItem.purchase_price);
        toast.success("item increased");
      }
      state.totalAmount = state.cartItems.reduce(
        (total, item) =>
          total + Number(item.purchase_price) * Number(item.quantity),
        0
      );
    },
    deleteItem: (state, action) => {
      const id = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);
      if (existingItem) {
        state.cartItems = state.cartItems.filter((item) => item.id !== id);
        state.totalQuantity = state.totalQuantity - existingItem.quantity;
      }
      state.totalAmount = state.cartItems.reduce(
        (total, item) =>
          total + Number(item.purchase_price) * Number(item.quantity),
        0
      );
    },
    setIncreaseItemQTY: (state, action) => {
      state.totalQuantity++;
      const itemIndex = state.cartItems.find(
        (item) => item.id === action.payload.id
      );
      itemIndex.quantity++;
      itemIndex.totalPrice =
        Number(itemIndex.totalPrice) + Number(action.payload.purchase_price);

      state.totalAmount = state.cartItems.reduce(
        (total, item) =>
          total + Number(item.purchase_price) * Number(item.quantity),
        0
      );
    },
    setDecreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (itemIndex.quantity > 0) {
        state.totalQuantity--;
        itemIndex.quantity--;
        itemIndex.totalPrice =
          Number(itemIndex.totalPrice) - Number(action.payload.purchase_price);
      }

      state.totalAmount = state.cartItems.reduce(
        (total, item) =>
          total + Number(item.purchase_price) * Number(item.quantity),
        0
      );
    },
    setClearCartItems: (state, action) => {
      state.cartItems = [];
      state.totalAmount = 0;
      state.totalQuantity = 0;
      console.log(state.totalQuantity);
      console.log(state.cartItems);

      toast.success("Cart is Cleared");
    },
  },
});
export const {
  setOpenCart,
  setCloseCart,
  setAddItemToCart,
  deleteItem,
  setIncreaseItemQTY,
  setDecreaseItemQTY,
  setClearCartItems,
} = CartSlice.actions;
export const selectCartState = (state) => state.cart.cartState;
export const selectCartItems = (state) => state.cart.cartItems;
export default CartSlice.reducer;
