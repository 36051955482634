import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "../../reduxtk/slices/authSlice";
import { clearMessage } from "../../reduxtk/slices/message";
import FormExtra from "./FormExtra";
import { useForm } from "react-hook-form";

function LoginComponent() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    // setLoading(true);

    // dispatch(login(data.email, data.password))
    //   .unwrap()
    //   .then(() => {
    //     navigate("/profile");
    //     window.location.reload();
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
    reset();
  };

  // let navigate = useNavigate();

  // const [loading, setLoading] = useState(false);
  // const { isLoggedIn } = useSelector((state) => state.auth);
  // const { message } = useSelector((state) => state.message);

  // useEffect(() => {
  //   dispatch(clearMessage());
  // }, [dispatch]);

  // if (isLoggedIn) {
  //   return <Navigate to="/profile" />;
  // }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-10 space-y-6 max-w-md mx-auto "
    >
      <div className="my-5 text-start text-white">
        <label htmlFor="Email Address" className="sr-only">
          Email address
        </label>
        <input
          className=" bg-transparent rounded-sm appearance-none  block w-full px-3 py-2 border-b b-white/60 placeholder-white/50 text-white focus:outline-none focus:bg-transparent   sm:text-sm"
          type="email"
          id="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
          placeholder="Email address"
        />
        {errors.email && (
          <p className="max-w-md text-red-700 mt-2 px-1 text-sm font-normal">
            {errors.email.message}
          </p>
        )}
      </div>
      <div className="my-5 text-start text-white">
        <label htmlFor="Password" className="sr-only">
          Password
        </label>
        <input
          className=" bg-transparent rounded-sm appearance-none  block w-full px-3 py-2 border-b b-white/60 placeholder-white/50 text-white focus:outline-none focus:bg-transparent   sm:text-sm"
          type="password"
          id="password"
          {...register("password", {
            required: true,
            validate: {
              checkLength: (value) => value.length >= 6,
              matchPattern: (value) =>
                /(?=.*\d)(?=.*[a-z])(?!.*\s)(?=.*[!@#$*])/.test(value),
            },
          })}
          placeholder="Password"
        />
        {errors.password && errors.password.type === "required" && (
          <p className="max-w-md  text-red-700 mt-2  px-1  text-sm font-normal">
            Password is required.
          </p>
        )}
        {errors.password?.type === "checkLength" && (
          <p className="max-w-md text-red-700 mt-2  px-1  text-sm font-normal">
            Password should be at-least 6 characters.
          </p>
        )}
        {errors.password?.type === "matchPattern" && (
          <p className="max-w-md text-red-700 mt-2  px-1  text-sm font-normal">
            Password should contain at least one lowercase letter, digit, and
            special symbol.
          </p>
        )}
      </div>
      <FormExtra />
      <button
        className="py-3 w-full text-md font-medium rounded-md text-white bg-gradient-to-br from-[#06283D] to-[#0a486e] focus:outline-none focus:ring-2 focus:ring-offset-2  mt-10"
        type="submit"
      >
        Login
      </button>
    </form>
  );
}

export default LoginComponent;
