import React from "react";
import Photo from "../../assests/a-Childrens-Dentist.jpeg";
import { motion } from "framer-motion";
function PageTwo() {
  return (
    <section className="text-gray-600 body-font mt-28 mb-20  relative">
      <div className="lg:h-full md:h-full  md:w-2/4 absolute  bg-gradient-to-l from-[#47B5FF] to-[#06283D] lg:top-0  right-0 -z-50 -mt-8 "></div>
      <div className="container max-w-6xl mx-auto flex px-5  md:flex-row flex-col items-center">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.7 }}
          className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
        >
          <h1 className="tracking-widest title-font sm:text-3xl text-2xl mb-4 font-bold text-gray-900">
            HOW WE MAKE A DIFFERENCE
          </h1>
          <p className="text-xl leading-relaxed text-gray-700">
            At Oxymarkt, we strive to provide dental students in the UAE with a
            comprehensive platform that meets all their needs. Our platform not
            only offers a convenient solution for obtaining materials and
            accessing information, but also guarantees the best prices for
            students. This will allow them to focus on their studies without
            worrying about the cost.
          </p>
        </motion.div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="hover:scale-110 transition-all duration-300 object-cover object-center rounded shadow-md shadow-black/50"
            alt="hero"
            src={Photo}
          />
        </div>
      </div>
    </section>
  );
}

export default PageTwo;
