import React from "react";

import "./App.css";

import Layout from "./components/layout/Layout";
import Cart from "./pages/Cart";
import Likes from "./pages/Likes";

function App() {
  return (
    <div className="App">
      <Cart />
      <Likes />
      <Layout />
    </div>
  );
}

export default App;
