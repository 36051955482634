import React, { useEffect, useState } from "react";
import ProductsList from "../components/products/ProductsList";
import { HiOutlineSearch } from "react-icons/hi";
import _ from "lodash";
import {
  useGetAllCategoriesQuery,
  useGetLatestProductsQuery,
} from "../reduxtk/productsApi";

import BeatLoader
 from "react-spinners/BeatLoader";
function Products() {
  const { data: latestProducts,isSuccess:isLatestProductsSuccess,isLoading: isLatestProductsLoading} = useGetLatestProductsQuery();
  const { data: allCategories ,isSuccess:isCategoriesSuccess,isLoading: isCategoriesLoading} = useGetAllCategoriesQuery();
  const latestProductsArray = latestProducts?.products;
  const [latestproductsData, setLatestProductsData] =
    useState(latestProductsArray);
  const [categoriesData, setCategoriesData] = useState(allCategories);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLatestProductsData(latestProductsArray);

    setCategoriesData(allCategories);
  }, [latestProductsArray, allCategories]);
  console.log(latestProductsArray);

  console.log(allCategories);
  const handleSearch = (e) => {
    const searchItem = e.target.value;
    const latestSearchedProducts = latestProductsArray.filter((item) =>
      item.name.toLowerCase().includes(searchItem.toLowerCase())
    );

    setLatestProductsData(latestSearchedProducts);
  };

  const handleFilter = (e) => {
    const category = e.target.value;
    const latestCategoryItems = latestProductsArray.filter((item) =>
      item.category_ids.map((categoryId) => categoryId.id === category)
    );
console.log(category);
    setLatestProductsData(latestCategoryItems);
  };

  return (
    <div className="min-h-screen" >
      <section className="text-gray-600 body-font ">
         {
        isCategoriesLoading&&isLatestProductsLoading ?
        <div className="flex items-center justify-center  min-h-screen">
          <BeatLoader

        color="#47B5FF"
        
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
        </div>
         :null
      }
      {
        isCategoriesSuccess && isLatestProductsSuccess ? <div className="container flex  flex-col px-2 py-10  mx-auto items-center justify-center gap-10 ">
          <div className=" flex items-center justify-center w-6/12 h-12 rounded-lg focus-within:shadow-lg focus-within:border-[#47B5FF]/50 overflow-hidden border">
            <input
              className="bg-[#06283D]/10 h-full w-full outline-none text-grey-900 text-md   px-3"
              type="search"
              id="search"
              placeholder="What are you looking for?"
              onChange={handleSearch}
            />
            <button
              type="submit"
              className="flex items-center justify-center bg-[#06283D]/10  px-4 py-4"
            >
              <HiOutlineSearch size={20} className="" />
            </button>
          </div>
          <div className="flex items-center min-h-full  justify-between py-10 gap-10">
            <button
              value="all"
              onClick={() => {
                setLatestProductsData(latestProductsArray);
              }}
              className="flex rounded-md items-center w-36 justify-center bg-white border-2 active:bg-[#06283D] active:border-[#47B5FF] active:text-white   hover:bg-[#06283D] hover:text-white duration-200  px-4 py-6"
            >
              All
            </button>
            {categoriesData?.map((item) => (
              <button
                value={item.id}
                onClick={handleFilter}
                className="flex rounded-md items-center w-48 justify-center bg-white border-2 focus-within:bg-[#06283D] focus-within:border-[#47B5FF] focus-within:text-white   hover:bg-[#06283D] hover:text-white duration-200  px-4 py-6"
              >
                {item.name}
              </button>
            ))}
          </div>
        </div> :null
      }
        
      </section>

      <ProductsList data={latestproductsData} />
    </div>
  );
}

export default Products;
