import React from "react";
import { toast } from "react-toastify";
import { HiOutlineArrowLeft } from "react-icons/hi2";

function CartCount({ onCartToggle, clearCart, count }) {
  return (
    <div className="flex items-center justify-between px-6 py-6 sticky w-full">
      <div className="flex items-center cursor-pointer">
        <HiOutlineArrowLeft onClick={onCartToggle} size={28} color="06283D" />

        <span className="px-4 text-base font-medium">
          Shopping Bag ({count})
        </span>
      </div>

      <div className="flex items-center cursor-pointer" onClick={clearCart}>
        <h1 className="text-[#06283D] text-base font-medium px-2 py-1 border border-[#06283D] rounded-sm shadow cursor-pointer">
          Clear Cart
        </h1>
      </div>
    </div>
  );
}

export default CartCount;
