import React from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLikes } from "../../reduxtk/slices/LikesSlice";
import { useGetWishListItemsQuery } from "../../reduxtk/wishListApi";
function LikesButton() {
  const { data: wishlistItems } = useGetWishListItemsQuery();
  const items = wishlistItems?.map((item) => item.product);
  const count = items? items.length : 0;
  const dispatch = useDispatch();
  const onLikesToggle = () => {
    dispatch(setOpenLikes({ likesState: true }));
  };
  return (
    <div
      onClick={onLikesToggle}
      className="relative active:scale-110 transition-all duration-200  inline-flex mx-6 mb-2  cursor-pointer lg:inline-block"
    >
      <div className="t-0 absolute left-4">
        <p className="flex h-2 w-2 items-center justify-center rounded-full bg-[#47B5FF] p-3 text-xs text-white shadow-sm shadow-[#47B5FF] ">
          {count}
        </p>
      </div>
      <AiOutlineHeart
        size={30}
        color="#06283D"
        className="mt-3  transition-all duration-300 "
      />
    </div>
  );
}

export default LikesButton;
