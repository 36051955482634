import React, { useEffect, useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearMessage } from "../../reduxtk/slices/message";
import { register } from "../../reduxtk/slices/authSlice";
import { useForm } from "react-hook-form";
function SignupComponent() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const [passwordMatch, setPasswordMatch] = useState(true);
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  const handleConfirmPassword = () => {
    setPasswordMatch(password === confirmPassword);
  };
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(signupState);
  //   if (signupState["password"] !== signupState["confirm-password"]) {
  //     console.log("not matched");
  //     toast.error("Passwords did not matched");
  //   }
  //   setSuccessful(false);

  //   dispatch(register(setSignupState))
  //     .unwrap()
  //     .then(() => {
  //       setSuccessful(true);
  //     })
  //     .catch(() => {
  //       setSuccessful(false);
  //     });
  // };

  return (
    <>
      <ToastContainer
        theme="dark"
        pauseOnHover={false}
        autoClose={3000}
        transition={Slide}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-10 space-y-6 max-w-md mx-auto"
      >
        <div className="my-5 text-start text-white">
          <label htmlFor="Username" className="sr-only">
            Username
          </label>
          <input
            className=" bg-transparent rounded-sm appearance-none  block w-full px-3 py-2 border-b b-white/60 placeholder-white/50 text-white focus:outline-none focus:bg-transparent   sm:text-sm"
            type="username"
            id="username"
            {...register("username", {
              required: "Username is required.",
              pattern: {
                value:
                  /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
                message: "username is not valid.",
              },
            })}
            placeholder="Username"
          />
          {errors.username && (
            <p className="max-w-md text-red-700  px-1 text-sm font-normal">
              {errors.username.message}
            </p>
          )}
        </div>
        <div className="my-5 text-start text-white">
          <label htmlFor="Email Address" className="sr-only">
            Email address
          </label>
          <input
            className=" bg-transparent rounded-sm appearance-none  block w-full px-3 py-2 border-b b-white/60 placeholder-white/50 text-white focus:outline-none focus:bg-transparent   sm:text-sm"
            type="email"
            id="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            placeholder="Email address"
          />
          {errors.email && (
            <p className="max-w-md text-red-700 mt-2 px-1 text-sm font-normal">
              {errors.email.message}
            </p>
          )}
        </div>
        <div className="my-5 text-start text-white">
          <label htmlFor="Password" className="sr-only">
            Password
          </label>
          <input
            className=" bg-transparent rounded-sm appearance-none  block w-full px-3 py-2 border-b b-white/60 placeholder-white/50 text-white focus:outline-none focus:bg-transparent   sm:text-sm"
            type="password"
            id="password"
            {...register("password", {
              required: true,
              validate: {
                checkLength: (value) => value.length >= 6,
                matchPattern: (value) =>
                  /(?=.*\d)(?=.*[a-z])(?!.*\s)(?=.*[!@#$*])/.test(value),
              },
            })}
            placeholder="Password"
          />
          {errors.password && errors.password.type === "required" && (
            <p className="max-w-md  text-red-700 mt-2  px-1  text-sm font-normal">
              Password is required.
            </p>
          )}
          {errors.password?.type === "checkLength" && (
            <p className="max-w-md text-red-700 mt-2  px-1  text-sm font-normal">
              Password should be at-least 6 characters.
            </p>
          )}
          {errors.password?.type === "matchPattern" && (
            <p className="max-w-md text-red-700 mt-2  px-1  text-sm font-normal">
              Password should contain at least one lowercase letter, digit, and
              special symbol.
            </p>
          )}
        </div>
        <div className="my-5 text-start text-white">
          <label htmlFor="confirmPassword" className="sr-only">
            Confirm Password
          </label>
          <input
            className=" bg-transparent rounded-sm appearance-none  block w-full px-3 py-2 border-b b-white/60 placeholder-white/50 text-white focus:outline-none focus:bg-transparent   sm:text-sm"
            type="password"
            id="confirmPassword"
            {...register("confirmPassword", {
              validate: (value) =>
                value === password || "Passwords do not match",
            })}
            onChange={handleConfirmPassword}
            placeholder="Confirm Password"
          />

          {!passwordMatch && (
            <p className="max-w-md  text-red-700 mt-2  px-1  text-sm font-normal">
              Passwords do not match.
            </p>
          )}
        </div>

        <button
          className="py-3 w-full text-md font-medium rounded-md text-white bg-gradient-to-br from-[#06283D] to-[#0a486e] focus:outline-none focus:ring-2 focus:ring-offset-2 mt-6"
          onSubmit={handleSubmit}
        >
          Create Account
        </button>
        <button
          className="py-3 w-full flex flex-row gap-2 justify-center items-center  text-md font-medium rounded-md text-white bg-gradient-to-br from-[#AD0000] to-[#cd2626] focus:outline-none focus:ring-2 focus:ring-offset-2 mt-3"
          onSubmit={handleSubmit}
        >
          <FcGoogle size={20} className="" />
          Signup with Google
        </button>
        <button
          className="py-3  w-full flex flex-row gap-2 justify-center items-center text-md font-medium rounded-md text-white bg-[#1877F2] focus:outline-none focus:ring-2 focus:ring-offset-2 mt-3"
          onSubmit={handleSubmit}
        >
          <BsFacebook size={20} className="" />
          Signup with Facebook
        </button>
      </form>
    </>
  );
}

export default SignupComponent;
