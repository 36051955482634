import React, { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FiShoppingBag } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "50%" },
};
function ProfileButton() {
  const loggedOut = () => {
    localStorage.removeItem("userLogged");
    window.location.reload(true);
  };
  const [open, setOpen] = useState(false);
  return (
    <div className="inline-flex items-center  justify-center">
      <div className="relative bottom-1 lg:bottom-0 right-2">
        <button
          onClick={() => setOpen(!open)}
          className="flex  items-center justify-center mx-1 p-1.5 bg-[#47B5FF] rounded-full shadow-md "
        >
          <AiOutlineUser size={24} className="text-white items-center" />
        </button>
        <motion.ul
          animate={open ? "open" : "closed"}
          variants={variants}
          className={`absolute right-0 w-48 py-2 mt-2 bg-gray-100/90 backdrop-blur-xl text-[#06283D]  rounded-md shadow-xl ${
            open ? "block " : "hidden"
          }`}
        >
          <Link
            to="/profile"
            className="flex cursor-pointer gap-2 w-full items-center px-3 py-2 text-sm hover:text-[#47B5FF]"
          >
            <AiOutlineUser size={20} />
            Manage My Account
          </Link>
          <Link
            to="/orders"
            className="flex cursor-pointer gap-2 w-full items-center px-3 py-2 text-sm hover:text-[#47B5FF]"
          >
            <FiShoppingBag size={20} />
            My Orders
          </Link>
          <li
            onClick={loggedOut}
            className="flex cursor-pointer gap-2 w-full items-center px-3 py-2 text-sm hover:text-[#47B5FF]"
          >
            <BiLogOut size={20} />
            Logout
          </li>
        </motion.ul>
      </div>
    </div>
  );
}

export default ProfileButton;
