import React from "react";
import Photo from "../../assests/equi_home.png";
import { motion } from "framer-motion";
function PageFour() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ delay: 0.7 }}
      className="container mx-auto flex px-5 py-32 md:flex-row flex-col items-center"
    >
      <div className="lg:flex-grow px-10 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <h1 className=" sm:text-3xl text-2xl tracking-widest mb-4 font-bold text-gray-900">
          WHO WE ARE
        </h1>
        <p className="mb-8 leading-relaxed font-semibold md:pr-20 text-lg">
          Copper mug try-hard pitchfork pour-over freegan heirloom neutra air
          plant cold-pressed tacos poke beard tote bag. Heirloom echo park
        </p>
      </div>
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        <img
          className="hover:scale-110 transition-all duration-300 object-cover object-center rounded"
          alt="hero"
          src={Photo}
        />
      </div>
    </motion.div>
  );
}

export default PageFour;
