import React from "react";

import Logo from "../../assests/Oxy_Markt Logo_ White png 1.png";
import Header from "../../components/auth/Header";
import SignupComponent from "../../components/auth/SignupComponent";
import Photo from "../../assests/login_pic.JPG";
function SignUp() {
  return (
    <div className="h-full flex flex-row">
      <div className=" lg:basis-7/12 md:basis-1/2 hidden md:flex flex-col items-center justify-center">
        <div className="bg-white max-w-4xl  mx-20">
          <img src={Photo} alt="" className=" object-cover object-center" />
        </div>
      </div>
      <div className="bg-gradient-to-l from-[#47B5FF] via-[#256D85] to-[#06283D] lg:basis-5/12 md:basis-1/2 w-full md:shadow-[-5px_-5px_20px_0px_rgba(0,0,0,60%)]">
        <div className="container  py-16 px-6 mx-auto items-center justify-center">
          <img src={Logo} alt="" className="object-center w-80 mx-auto" />
          <Header
            heading="Create an account"
            paragraph="Already have an account? "
            linkName="Login"
            linkUrl="/login"
          />
          <SignupComponent />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
