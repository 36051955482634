import React, { useEffect, useState } from "react";
import ReactStarRating from "react-star-ratings-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsBagPlus } from "react-icons/bs";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useAddWishListItemMutation, useGetWishListItemsQuery } from "../../reduxtk/wishListApi";
import { useAddToCartMutation, useGetCartItemsQuery } from "../../reduxtk/cartApi";
import {API_URL} from "../../env";
function ProductCard({ item }) {
  const { data: cartItems,refetch:cartRefetch } = useGetCartItemsQuery();
  const {data :wishlistItems,refetch:wishlistRefetch } = useGetWishListItemsQuery()
  const [addToWishlist,{isSuccess:wishlistSuccess} ] = useAddWishListItemMutation();
  const [addToCart , {isSuccess:cartSuccess}] = useAddToCartMutation();
  const addToWishlistItem = () => {
    addToWishlist(item.id);
    toast.success("Item added to wishlist successfully");
  };
  useEffect(()=>{
    cartRefetch()
     wishlistRefetch()
  } , [wishlistSuccess,cartSuccess])

  const Add = () => {
    let body = new FormData();
    body.append("id", item.id);
    body.append("quantity", item.minimum_order_qty);
    addToCart(body);
     toast.success("Item added to cart successfully");
  };

  const image = item.images[0];
  return (
    <>
      <div className="block border bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden my-4 mx-1 hover:scale-105 duration-300  ease-in-out min-w-full ">
        <Link
          className="block relative h-48 rounded overflow-hidden  "
          to={`/products/${item.slug}`}
        >
          <div className=" inline-block absolute left-0 rounded-md ">
            {item.discount > 0 ? (
              <div className=" bg-green-600 text-white px-2 py-0.5 font-medium rounded-md">
                -${item.discount} Off
              </div>
            ) : null}
          </div>
          <img
            alt="ecommerce"
            className="object-cover object-center mx-auto  max-w-full max-h-full block p-2"
            src={ API_URL + `/storage/app/public/product/${image}`}
          />
        </Link>
        <div className="my-4 text-left mx-2 cursor-default">
          <h2 className="text-gray-900 title-font text-md font-medium">
            {item.name.slice(0, 30) + "..."}
          </h2>

          <p className="text-red-700 font-semibold text-lg space-x-2">
            <span className="text-md font-semibold">$</span>
            {item.unit_price - item.discount}
            {item.discount > 0 ? (
              <span className="text-md text-gray-500 line-through font-semibold">
                ${item.unit_price}
              </span>
            ) : null}
          </p>
          <div className="flex items-center justify-between ">
            <ReactStarRating
              numberOfStar={5}
              numberOfSelectedStar={item?.rating[0]?.average}
              colorFilledStar="#FFAD33"
              colorEmptyStar="#CDDFF2"
              starSize="20px"
              spaceBetweenStar="2px"
              disableOnSelect={true}
            />
            <div className="flex items-center justify-between gap-2 ">
              <AiOutlineHeart
                size={23}
                className="text-gray-700 cursor-pointer  hover:scale-110 transition-all duration-300"
                onClick={addToWishlistItem}
              />

              <BsBagPlus
                size={20}
                onClick={Add}
                className="text-gray-900 cursor-pointer hover:scale-110 transition-all duration-300"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
