import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {API_URL} from "../env";

export const wishListApi = createApi({
  reducerPath: "wishListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + "/api/v1/customer",
    prepareHeaders: (headers, { getState }) => {
      function getAccessToken() {
        let user = localStorage.getItem("user");
        return user ? JSON.parse(user)["token"]["access_token"] : null;
      }
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWishListItems: builder.query({ query: () => "wish-list" }),
    addWishListItem: builder.mutation({
      query: (id) => ({
        url: "wish-list/add",
        method: "POST",
        body: {
          product_id: id,
        },
      }),
    }),
    deleteWishListItem: builder.mutation({
      query: (id) => ({
        url: "wish-list/remove",
        method: "DELETE",
        body: {
          product_id: id,
        },
      }),
    }),
  }),
});
export const {
  useGetWishListItemsQuery,
  useAddWishListItemMutation,
  useDeleteWishListItemMutation,
} = wishListApi;
