import React from "react";
import ProductCard from "./ProductCard";

function ProductsList({ data }) {
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container  py-10 mx-auto">
          <div className="grid items-center justify-items-center lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 mx-3   ">
            {data?.map((item, index) => (
              <ProductCard item={item} key={index} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsList;
