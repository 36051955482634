import React, { useEffect , useState } from "react";
import ReactDOM from "react-dom";
import CartCount from "../components/cart/CartCount";
import CartEmpty from "../components/cart/CartEmpty";
import CartItem from "../components/cart/CartItem";
import CheckOutCart from "../components/cart/CheckOutCart";
import { selectCartState, setCloseCart } from "../reduxtk/slices/CartSlice";
import { useDispatch, useSelector } from "react-redux";
import {  useClearCartMutation, useGetCartItemsQuery } from "../reduxtk/cartApi";

function Cart() {

  const { data: cartItems , refetch , isSuccess:isCartItemsSuccess } = useGetCartItemsQuery();
  const [clearCartItems,{isSuccess:isClearedSuccess}] = useClearCartMutation();
  const count = cartItems?.length;
  const dispatch = useDispatch();
  const ifCartState = useSelector(selectCartState);
  const [totalPrice , setTotalPrice ] = useState(0);
  const onCartToggle = () => {
    dispatch(setCloseCart({ cartState: false }));
  };

  const clearCart = () => {
    clearCartItems(1);
  };
  useEffect(() => {

      refetch();
    

     let total = 0;
    if(cartItems){
        cartItems?.map((item)=>{
          total += item.quantity*(item.price - item.discount);
        });
    }
      setTotalPrice(total);
     
  }, [isClearedSuccess , cartItems]);


  return ReactDOM.createPortal(
    <div
      className={` fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm w-full h-screen opacity-100 z-50 ${
        ifCartState
          ? "opacity-100 visible translate-x-0 duration-500"
          : "opacity-0 invisible translate-x-8 duration-500"
      }`}
    >
      <div className="overflow-y-scroll  bg-white/90 shadow-2xl h-screen max-w-xl w-full absolute right-0  ">
        <CartCount
          onCartToggle={onCartToggle}
          clearCart={clearCart}
          count={count}
        />
        {count === 0 ? (
          <CartEmpty />
        ) : (
          <>
            {cartItems?.map((item, i) => (
              <CartItem key={i} item={item} />
            ))}

            <CheckOutCart onCartToggle={onCartToggle} totalPrice={totalPrice}/>
          </>
        )}
      </div>
    </div>,
    document.getElementById("cart")
  );
}

export default Cart;
