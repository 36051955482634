import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { FiShoppingBag } from "react-icons/fi";
import { BiLogOut } from "react-icons/bi";
import { useForm } from "react-hook-form";
import Reset from "./auth/Reset";
function Profile() {
   function getUserInfo() {
        let user = localStorage.getItem("user");
        return user ? JSON.parse(user) : null;
      }
      console.log(getUserInfo())
  const [successMsg, setSuccessMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    console.log(data.firstname);
    setSuccessMsg("Your data is edited.");
    reset();
  };
  return (
    <div className=" body-font bg-[#F7F7F7]">
      <div className=" container flex md:flex-row flex-col gap-4  py-12 sm:py-8 lg:py-20 max-w-6xl mx-auto px-5">
        <div className="bg-white  flex-col justify-center space-y-5 h-fit  md:basis-4/12 shadow-lg px-10 shadow-black/30 rounded-md py-10">
          <div className="shadow-[0_5px_40px_-10px_rgba(0,0,0,1)] w-28 h-28 mx-auto flex items-center justify-center  rounded-full">
            <div className="bg-[#06283D] rounded-full w-24 h-24 flex items-center justify-center">
              <AiOutlineUser size={70} className="text-white " />
            </div>
          </div>
          <h1 className="text-xl font-bold tracking-wide ">{getUserInfo().f_name+" "+getUserInfo().l_name}</h1>
          <div className="h-0.5 w-60 mx-auto bg-gray-300 rounded"></div>
          <div className="flex flex-col gap-4 text-start  py-4">
            <div className="">
              <h1 className="font-semibold">Email Address :</h1>
              <p className="text-gray-500">{getUserInfo().email}</p>
            </div>
            <div className="">
              <h1 className="font-semibold">Phone Number :</h1>
              <p className="text-gray-500">{getUserInfo().phone
}</p>
            </div>
          </div>
          <div className="flex flex-col py-5 items-start gap-5 text-[#06283D]  ">
            <Link to="/orders" className="flex gap-2">
              <FiShoppingBag size={22} />
              <h1 className=" font-medium">My Orders</h1>
            </Link>
            <div className="flex gap-2">
              <BiLogOut size={22} />
              <h1 className=" font-medium">Logout</h1>
            </div>
          </div>
        </div>
        <div className="bg-white md:basis-8/12 container py-10 px-10 flex flex-col text-start space-y-5 ">
          {successMsg && (
            <p className="text-start text-green-500 text-md">{successMsg}</p>
          )}
          <h1 className=" font-medium text-lg text-[#06283D] ">
            Edit Your Profile
          </h1>

          <form
            className=" container grid md:grid-cols-2 gap-4 mx-auto py-6 space-y-2   "
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="text-start  space-y-2 font-medium">
              <label className="">First Name</label>
              <input
              disabled
                type="text"
                {...register("firstname", {
                  required: "First Name is required.",
                })}
                className=" bg-gray-100 rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
              />
              {errors.firstname && (
                <p className="max-w-md text-red-700  px-1  text-sm font-normal">
                  {errors.firstname.message}
                </p>
              )}
            </div>
            <div className="text-start font-medium">
              <label className="">Last Name</label>
              <input
              disabled
                type="text"
                {...register("lastname", {
                  required: "Last Name is required.",
                })}
                className=" bg-gray-100 rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
              />
              {errors.lastname && (
                <p className=" max-w-md text-red-700  px-1 text-sm font-normal">
                  {errors.lastname.message}
                </p>
              )}
            </div>
            
            <div className="text-start space-y-2 font-medium">
              <label className="">Phone Number</label>
              <input
              disabled
                type="text"
                {...register("phoneNumber", {
                  required: true,
                  validate: {
                    matchPattern: (value) => /^\d*\.?\d*$/.test(value),
                  },
                })}
                className=" bg-gray-100 rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
              />
              {errors.phoneNumber?.type === "required" && (
                <p className="max-w-md text-red-700  px-1 text-sm font-normal">
                  Phone Number is required
                </p>
              )}

              {errors.phoneNumber?.type === "matchPattern" && (
                <p className="max-w-md text-red-700  px-1 text-sm font-normal">
                  Phone Number should contain only digits
                </p>
              )}
            </div>
        
            <div className="text-start space-y-2  font-medium">
              <label className="">Email Address</label>
              <input
              disabled
                type="text"
                {...register("email", {
                  required: "Email is required.",
                  pattern: {
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    message: "Email is not valid.",
                  },
                })}
                className=" bg-gray-100 rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
              />
              {errors.email && (
                <p className="max-w-md text-red-700  px-1 text-sm font-normal">
                  {errors.email.message}
                </p>
              )}
            </div>

            <button
              disabled={true}
              type="submit"
              className="py-3 w-40 text-md font-medium rounded-md text-white bg-gradient-to-br from-[#06283D] to-[#0a486e] focus:outline-none focus:ring-2 focus:ring-offset-2  mt-10"
            >
              Save Changes
            </button>
          </form>
          <div className="h-0.5 w-full mx-auto  bg-gray-300 rounded"></div>
          <Reset />
        </div>
      </div>
    </div>
  );
}

export default Profile;
