import React, { useEffect  , useState } from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
import {
  useDeleteFromCartMutation,
  useGetCartItemsQuery,
  useUpdateCartMutation,
} from "../../reduxtk/cartApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {API_URL} from "../../env";

function CartItem({ item}) {
  
  const { data: cartItems , refetch } = useGetCartItemsQuery();
  const [deleteFromCart,{isSuccess:isDeleteSuccess}] = useDeleteFromCartMutation();
  const [updateQuantity , { isLoading:isUpdateLoading ,isSuccess:isUpdateSuccess}] = useUpdateCartMutation();

  const [totalPrice , setTotalPrice ] = useState(0);
  const [isRefetch , setIsRefetch] = useState(false);
 

  const deleteProduct = () => {
    deleteFromCart(item.id);
    toast.success("Item is removed from cart successfully");
  };
  const increaseProduct = () => {
    let body = {
      "key": item.id,
      "quantity": item.quantity + 1,
    };
    updateQuantity(body);
    //refetch();
  };
  const decreaseProduct = () => {
    if(item.quantity > 0){

      let body = {
        "key": item.id,
        "quantity": item.quantity - 1,
      };
      if((item.quantity - 1) === 0 ){
         deleteFromCart(item.id);
      }
      updateQuantity(body);
      //refetch();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() =>{
      refetch();
    } , 300);

    return () => {
      clearTimeout(timer);
    }
  }, [isUpdateSuccess,isDeleteSuccess]);

  
  

  return (
    <>
      <div className="flex items-center   justify-between  w-full px-5 py-5   overflow-hidden  border-b border-glass">
        <div className="h-28 w-32  border bg-white  rounded  hover:scale-105 transition-all duration-300 ease-in-out  overflow-hidden">
          <img
            src={ API_URL + `/storage/app/public/product/thumbnail/${item.thumbnail}`}
            alt="img"
            className="mx-auto  max-w-full max-h-full object-cover object-center p-2 "
          />
        </div>
        <div className="ml-10 flex flex-1 flex-col  gap-5">
          <div className="flex-col items-start justify-start leading-none">
            <h1 className="md:font-medium  md:text-lg text-base text-gray-800">
              {item.name}
            </h1>
          </div>
          <div className="flex  items-center justify-start rounded-md  ">
            <button
              disabled={isUpdateLoading}
              onClick={decreaseProduct}
              type="button"
              className=" border-2 w-8 h-6 flex items-center disabled:cursor-not-allowed justify-center active:scale-90"
            >
              <HiMinus size={14} className="text-gray-400 " />
            </button>
            <div className=" border-t-2 border-b-2   text-[#06283D]font-medium w-10 h-6 flex items-center justify-center">
              {item.quantity}
            </div>
            <button
              disabled={isUpdateLoading}
              onClick={increaseProduct}
              type="button"
              className="bg-[#47B5FF]  border-2  hover:scale-110 transition-all duration-200 ease-in-out disabled:cursor-not-allowed  border-[#47B5FF] w-8 h-6 flex items-center justify-center active:scale-90"
            >
              <HiPlus size={14} className="text-white " />
            </button>
          </div>
        </div>

        <div className="grid items-center gap-8">
          <div className="grid items-center justify-center">
            <h1 className="text-lg lg:text-base text-red-700 font-medium ">
              ${item.price - item.discount}
              {item.discount > 0 ? (
                <span className="text-md ml-1 text-gray-500 line-through font-semibold">
                  ${item.price}
                </span>
              ) : null}
            </h1>
          </div>
          <div className="flex items-center justify-center">
            <button
              onClick={deleteProduct}
              className="hover:scale-110 transition-all duration-200 ease-in-out   p-1 grid items-center justify-items-center w-6 h-6"
            >
              <HiOutlineTrash
                size={20}
                className="text-[#06283D] font-medium"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartItem;
