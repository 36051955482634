import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import LikesCount from "../components/likes/LikesCount";
import LikesEmpty from "../components/likes/LikesEmpty";
import LikesItem from "../components/likes/LikesItem";
import { selectLikesState, setCloseLikes } from "../reduxtk/slices/LikesSlice";
import { useGetWishListItemsQuery } from "../reduxtk/wishListApi";

function Likes() {
  const { data: wishlistItems } = useGetWishListItemsQuery();
  const items = wishlistItems?.map((item) => item.product);
  const count = items?.length;
  const dispatch = useDispatch();
  const ifLikesState = useSelector(selectLikesState);
  const onLikesToggle = () => {
    dispatch(setCloseLikes({ likesState: false }));
  };
  return ReactDOM.createPortal(
    <div
      className={` fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm w-full h-screen opacity-100 z-50 ${
        ifLikesState
          ? "opacity-100 visible translate-x-0 duration-500"
          : "opacity-0 invisible translate-x-8 duration-500"
      }`}
    >
      <div className="overflow-y-scroll  bg-white/90 shadow-2xl h-screen max-w-xl w-full absolute right-0 ">
        <LikesCount onLikesToggle={onLikesToggle} count={count} />
        {items?.length === 0 ? (
          <LikesEmpty />
        ) : (
          <>
            {items?.map((item, i) => (
              <LikesItem key={i} item={item} />
            ))}
          </>
        )}
      </div>
    </div>,
    document.getElementById("likes")
  );
}

export default Likes;
