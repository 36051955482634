import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
function Reset() {
  const [successMsg, setSuccessMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const password = useRef({});
  password.current = watch("newpassword", "");

  const onSubmit = (data) => {
    console.log(data);

    setSuccessMsg("Your password is updated.");
    reset();
  };

  return (
    <div>
      <form
      
     
        className=" container flex flex-col gap-2 mx-auto py-6 space-y-3   "
        onSubmit={handleSubmit(onSubmit)}
      >
        {successMsg && (
          <p className="text-start text-green-500 text-md">{successMsg}</p>
        )}
        <h1 className=" font-medium text-lg mb-4 text-[#06283D] ">
          Password Changes
        </h1>
        
        <div  className="text-start space-y-2  font-medium">
          <label className="">New Password</label>
          <input disabled
            type="password"
            name="newpassword"
            {...register("newpassword", {
              required: true,
              validate: {
                checkLength: (value) => value.length >= 6,
                matchPattern: (value) =>
                  /(?=.*\d)(?=.*[a-z])(?!.*\s)(?=.*[!@#$*])/.test(value),
              },
            })}
            className=" bg-gray-100 rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
          />
          {errors.newpassword && errors.newpassword.type === "required" && (
            <p className="max-w-md text-red-700  px-1  text-sm font-normal">
              Password is required.
            </p>
          )}
          {errors.newpassword?.type === "checkLength" && (
            <p className="max-w-md text-red-700  px-1  text-sm font-normal">
              Password should be at-least 6 characters.
            </p>
          )}
          {errors.newpassword?.type === "matchPattern" && (
            <p className="max-w-md text-red-700  px-1  text-sm font-normal">
              Password should contain at least one lowercase letter, digit, and
              special symbol.
            </p>
          )}
        </div>
        <div className="text-start space-y-2  font-medium">
          <label className="">Confirm Password</label>
          <input
          disabled
            type="password"
            name="confpassword"
            {...register("confpassword", {
              required: true,
              validate: (value) =>
                value === password.current || "The passwords do not match",
            })}
            className=" bg-gray-100 rounded-md appearance-none  block w-full px-3 py-2 border-b  placeholder-black text-black/70  focus:outline-[#47B5FF]  sm:text-md"
          />
        </div>
        {errors.confpassword && (
          <p className="max-w-md text-red-700  px-1  text-sm font-normal">
            {errors.confpassword.message}
          </p>
        )}
        <button
          disabled={true}
          type="submit"
          className="py-3 w-40 text-md font-medium rounded-md text-white bg-gradient-to-br from-[#06283D] to-[#0a486e] focus:outline-none focus:ring-2 focus:ring-offset-2  mt-10"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
}

export default Reset;
