import React from "react";
import Tooth from "../../assests/tooth_home.png";
import Icon1 from "../../assests/icon_1.png";
import Icon2 from "../../assests/icon_2.png";
import Icon3 from "../../assests/icon_3.png";
import Icon4 from "../../assests/icon_4.png";
import Icon5 from "../../assests/icon_5.png";
import Icon6 from "../../assests/icon_6.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
function PageTwo() {
  return (
    <div className="bg-[#06283D] flex flex-col min-h-full overflow-hidden ">
      <div className="container mx-auto flex px-4 py-24 md:flex-row flex-col items-center min-h-screen">
        <div className="md:w-1/3 text-white lg:flex-grow flex flex-col mb-16 md:mb-0 items-center mx-4 ">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.7 }}
            className="flex flex-col md:text-start text-center  items-center md:items-end md:mb-2 mb-10 "
          >
            <img src={Icon1} alt="" className="h-10 w-10  mb-3 " />
            <p className=" font-medium md:mr-5">
              Wide range of dental medical productsOur online shop offers a wide
              range of dental medical products that meet the needs of dental
              practices and laboratories. From dental instruments, materials,
              equipment to hygiene and infection control products, we have it
              all.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.7 }}
            className="flex flex-col md:text-start text-center  items-center md:items-end md:mb-4 mb-10 md:mr-10"
          >
            <img src={Icon2} alt="" className="h-10 w-10 mb-3  " />
            <p className="font-medium md:mr-5 ">
              Individual sets for each universityWe also deal with each
              university individually: With Unisets for your university courses,
              we offer you a clear and quick ordering experience.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.7 }}
            className="flex flex-col md:text-start text-center  items-center md:items-end "
          >
            <img src={Icon3} alt="" className="h-10 w-10 mb-3 " />
            <p className="font-medium md:mr-5">
              {" "}
              Fair and transparent prices.The Oxymarkt is incredibly confusing.
              You can always rely on the fair offer from Oxymarkt.
            </p>
          </motion.div>
        </div>
        <div className=" md:w-1/3 text-white lg:flex-grow   flex flex-col   mb-16 md:mb-0 items-center ">
          <div className=" h-96 overflow-hidden mb-10">
            <img
              alt="content"
              className="object-cover object-center h-full w-full "
              src={Tooth}
            />
          </div>
          <div className="flex justify-center mt-10">
            <Link
              to={"/about"}
              className="hover:scale-110 transition-all duration-300 inline-flex text-white font-medium bg-[#47B5FF] border-0 py-3 px-14 focus:outline-none shadow-md shadow-[#47B5FF] rounded text-lg"
            >
              VIEW MORE
            </Link>
          </div>
        </div>
        <div className="md:w-1/3 text-white lg:flex-grow flex flex-col mb-16 md:mb-0 items-center mx-4 ">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.7 }}
            className="flex flex-col md:text-start text-center  items-center md:items-start mb-8 "
          >
            <img src={Icon4} alt="" className="h-10 w-10 mb-3  " />
            <p className=" font-medium md:ml-5">
              Fast and reliable deliveryWe offer fast and reliable delivery for
              dental medical supplies, with CO2-free options using eco-friendly
              packaging materials to minimize our environmental impact.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.7 }}
            className="flex flex-col md:text-start text-center  items-center md:items-start mb-10 md:ml-10"
          >
            <img src={Icon5} alt="" className="h-10 w-10 mb-3 " />
            <p className="font-medium md:ml-5">
              Order easily and securelyAt Oxymarkt you can easily pay with all
              common online payment options.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.7 }}
            className="flex flex-col md:text-start text-center  items-center md:items-start "
          >
            <img src={Icon6} alt="" className="h-10 w-10 mb-3 " />
            <p className="font-medium md:ml-5">
              {" "}
              Customer service support We are committed to providing excellent
              customer service and ensuring your satisfaction
              with every purchase
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default PageTwo;
