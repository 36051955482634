import React from "react";
import Photo1 from "../../assests/toothAb.png";
import Photo2 from "../../assests/tools.png";
import Photo3 from "../../assests/forceps.png";
import Photo4 from "../../assests/golden-crown.png";
import "./About.css";
import { motion } from "framer-motion";
function PageOne() {
  return (
    <section className="text-gray-600 body-font bg_about ">
      <div className="container px-5 py-20 mx-auto ">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.7 }}
          className="flex flex-col text-center  mb-10"
        >
          <h1 className="sm:text-4xl  text-2xl tracking-widest font-bold title-font mb-4 text-white">
            GET TO KNOW OXY
          </h1>
          <p className="lg:w-2/3 mx-auto tracking-wider leading-relaxed font-medium text-xl md:my-5 sm:mt-3 mt-10 sm:text-white text-gray-900">
            With Oxymarkt we would like to offer all dental students in UAE a
            platform where they can obtain their materials, get information, and
            use many other features.
          </p>
        </motion.div>
        <div className=" container lg:max-w-5xl max-w-7xl flex flex-wrap mx-auto   text-center ">
          <div className="p-4  md:w-1/4 sm:w-1/2 ">
            <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg shadow-black/50 flex items-center flex-col px-4 min-h-full py-4 rounded-lg hover:scale-110 transition-all duration-300">
              <img src={Photo1} alt="" className="h-20 w-20 mb-2" />
              <p className="leading-relaxed text-white ">
                Oxymarkt is dedicated to distributing innovative and value-added
                medical devices.
              </p>
            </div>
          </div>
          <div className="p-4  md:w-1/4 sm:w-1/2 ">
            <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg shadow-black/50 flex items-center flex-col min-h-full px-2  py-4 rounded-lg hover:scale-110 transition-all duration-300">
              <img src={Photo2} alt="" className="h-20 w-20 mb-2" />
              <p className="leading-relaxed text-white ">
                We represent a select group of international manufacturers from
                Germany, Switzerland, Austria, and Italy.
              </p>
            </div>
          </div>
          <div className="p-4  md:w-1/4 sm:w-1/2 ">
            <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg shadow-black/50 flex items-center min-h-full flex-col px-2  py-4 rounded-lg hover:scale-110 transition-all duration-300">
              <img src={Photo3} alt="" className="h-20 w-20 mb-2" />
              <p className="leading-relaxed text-white ">
                Our primary goal is to support healthcare professionals in
                achieving safe, and successful clinical outcomes.
              </p>
            </div>
          </div>
          <div className="p-4  md:w-1/4 sm:w-1/2 ">
            <div className="bg-[#052A41]/50 backdrop-blur-md shadow-lg shadow-black/50 flex items-center min-h-full flex-col px-2  py-4 rounded-lg hover:scale-110 transition-all duration-300">
              <img src={Photo4} alt="" className="h-20 w-20 mb-2" />
              <p className="leading-relaxed text-white min-h-30">
                we aim to assist dental students by providing them with all the
                materials they need during their studies at an affordable cost
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageOne;
