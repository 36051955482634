import React from "react";
import Photo from "../../assests/IMG_0339.jpg";
import { motion } from "framer-motion";
function PageThree() {
  return (
    <section className="text-gray-600 body-font mt-28 relative">
      <div className="lg:h-full md:h-full  md:w-2/4 absolute  bg-gradient-to-r from-[#47B5FF] to-[#06283D] lg:top-0  left-0 -z-50 -mt-8 "></div>
      <div className="container max-w-6xl mx-auto flex px-5  md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="hover:scale-110 transition-all duration-300 object-cover object-center rounded shadow-md shadow-black/50 mb-16 md:mb-0"
            alt="hero"
            src={Photo}
          />
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ delay: 0.7 }}
          className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
        >
          <h1 className="tracking-widest title-font sm:text-3xl text-2xl mb-4 font-bold text-gray-900">
            OUR VISION
          </h1>
          <p className="text-xl leading-relaxed text-gray-700">
            At Oxymarkt, we understand the importance of balancing cost and
            quality in the healthcare industry. Our clients and students can
            rely on us to offer medical devices that meet their needs and
            budget. With a single point of contact, the purchasing process is
            made easier for everyone. Our goal is to be the go-to source for all
            surgical and dental equipment needs, providing a comprehensive
            solution for both clients and students
          </p>
        </motion.div>
      </div>
    </section>
  );
}

export default PageThree;
