import React from "react";

function LikesEmpty() {
  return (
    <div className="flex items-center justify-center h-screen text-center text-gray-400 font-medium">
      Your Wish List is empty
    </div>
  );
}

export default LikesEmpty;
