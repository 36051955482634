import React from "react";
import PageOne from "../components/aboutPage/PageOne";
import PageTwo from "../components/aboutPage/PageTwo";
import PageThree from "../components/aboutPage/PageThree";
import PageFour from "../components/aboutPage/PageFour";
import Services from "../components/aboutPage/Services";

function About() {
  return (
    <>
      <PageOne />
      <PageTwo />
      <PageThree />
      <PageFour />
      <Services />
    </>
  );
}

export default About;
