import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {API_URL} from "../env";
export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + "/api/v1/",
    prepareHeaders: (headers, { getState }) => {
      function getAccessToken() {
        let user = localStorage.getItem("user");
        return user ? JSON.parse(user)["token"]["access_token"] : null;
      }
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCartItems: builder.query({ query: () => "cart" }),
    addToCart: builder.mutation({
      query: (body) => ({
        url: "cart/add",
        method: "POST",
        body: body,
      }),
    }),
    deleteFromCart: builder.mutation({
      query: (id) => ({
        url: "cart/remove",
        method: "DELETE",
        body: {
          key: id,
        },
      }),
    }),
    updateCart: builder.mutation({
      query: (body) => ({
        url: "cart/update",
        method: "PUT",
        body:body,
      }),

    }),
    clearCart: builder.mutation({
      query: (id) => ({
        url: "cart/remove-all",
        method: "DELETE",
        body: { key: id },
      }),
    }),
  }),
});
export const {
  useAddToCartMutation,
  useClearCartMutation,
  useDeleteFromCartMutation,
  useGetCartItemsQuery,
  useUpdateCartMutation,
} = cartApi;
